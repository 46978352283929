<template>
  <div class="modal-card" :class="kind === 'backward' ? '' : 'move-modal'">
    <header>
      <div class="modal-card-head">
        <h4 class="modal-card-title" v-if="loadTemplateOrders">
          Select Template
        </h4>
        <h4 class="modal-card-title" v-else>
          <div :class="isCutOrder ? 'is-flex is-justify-content-space-between line-height' : ''">
            {{ cardTitle }}
            <span v-if="isCutOrder" class="line-height required-label is-size-5 is-italic is-inline">
              * required
            </span>
          </div>
        </h4>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="cancel()"></i>
      </div>
      <div class="header-note" v-if="cardMessage && cardMessage.length > 0">
        <div class="is-size-5">
          <span class="has-text-white">Warning :</span>
        {{ cardMessage }}</div>
      </div>
    </header>
    <template-list v-if="loadTemplateOrders"
      :stage="cards[0].stage"
      :purpose="cards[0].purpose === 'kit' ? 'assembly' : 'general'"
      @fill-template-data="fillTemplateData"
      @close-template-modal="loadTemplateOrders = false"
    ></template-list>
    <section
      class="modal-card-body has-background-white pt-0"
      :class="setBackModal"
      v-show="!loadTemplateOrders"
      >
      <div
        class="select-template is-flex is-align-items-center is-justify-content-space-between"
        v-if="type === 'prefab-edit' && !isMulti"
      >
        <span class="is-size-3 has-text-black-bis">  {{ getTemplateName }}  </span>
        <button v-if="!newCard.isKit() && !isOneOrderPerItem"
          class="button has-background-black-bis has-text-weight-bold is-size-5"
          @click="loadTemplates">
           Select Template
          </button>
          <button v-if="isOneOrderPerItem"
            class="button has-background-black-bis has-text-weight-bold is-size-5"
            @click="openApplyTempToMultiItemsModal()">
          Apply Template To Selected
          </button>
      </div>
      <div class="column pt-0">
        <h4 class="has-text-black-bis is-italic is-size-3 line-height" v-if="renderDynamicCols(config)">
              {{ config.cardTitle }}
            </h4>
        <div class="columns is-multiline">
          <div class="column is-6" v-for="(col, idx) in cardConfig" :key="col.label">
             <div class="pb-2" v-if="(idx === 0) && isMulti && showMultiFieldStage.includes(type)">
                <div class="field">
                  <div class="field-label">
                    <label class="has-text-black-bis is-italic is-size-3 label">
                      Number Selected
                    </label>
                  </div>
                  <div class="field-body">
                    <span class="has-text-black-bis">({{selectedOrders.length}}) </span>
                  </div>
               </div>
             </div>
             <div class="pb-2" v-if="(idx === 0) && isMulti
               && showMultiFieldProject.includes(type)">
                <div class="field">
                  <div class="field-label">
                    <label class="has-text-black-bis is-italic is-size-3 label">
                      Project
                    </label>
                  </div>
                  <div class="field-body">
                    <span class="has-text-black-bis">{{projectName}}</span>
                  </div>
               </div>
             </div>
             <div class="is-pulled-right"
              v-if="(idx === 0) && type === 'prefab-edit' && !isMulti">
              <o-checkbox v-model="isOneOrderPerItem" @change="onePerItemChanged" class="p-0">
                <span class="has-text-black-bis is-size-3 line-height">
                  Create one Order per item
                </span>
              </o-checkbox>
            </div>
             <div class="pb-2" v-if="(idx === 1) && type === 'prefab-edit' && isMulti">
             <div class="field">
                <div class="field-body">
                  <field-template-select
                    titleLabel="Order Template"
                    stage="planning"
                    purpose="general"
                    :value="selectedTemplate"
                    @update:value="updateOrderTemplates($event)"
                    ></field-template-select>
                </div>
              </div>
            </div>
            <div class="modal-date mb-2">
              <div v-if="showDeliverMaterialDate && idx === 1" class="field">
                <div class="field-body">
                  <label class="label">Deliver Material By*</label>
                  <div class="field is-expanded">
                    <div class="control">
                      <mf-date :is-edit = "true" kind='dates'
                        @setDate="setDeliverMaterialDate"
                        :inputProps="{ kind: 'pickReqOnSite'}">
                      </mf-date>
                    </div>
                  </div>
                </div>
              </div>

              <base-column v-if="renderDynamicCols(col)" class="mt-2" :fields="columnFields(newCard, col)" :card="newCard">
              </base-column>
            </div>
            <div class="is-flex is-justify-content-space-between"
              v-if="(idx === 1) && type === 'prefab-edit'">
                <div class="field">
                  <div class="field-label">
                    <label class="has-text-black-bis is-italic is-size-3 label">
                      Item Bills Of Material
                    </label>
                  </div>
                  <div class="field-body">
                    <o-checkbox class="p-0" v-model="bomCreate" @change="toggleBomCheckbox" :disabled="disableBomCheckbox">
                      <span class="has-text-black-bis">
                        Create Bills Of Material
                      </span>
                    </o-checkbox>
                  </div>
                </div>
                <div class="field">
                  <div class="field-label">
                    <label class="has-text-black-bis is-italic is-size-3 label">
                      Deliver Materials
                    </label>
                  </div>
                  <div class="field-body">
                    <div class="is-flex is-align-items-center">
                      <span class="is-pulled-left">
                      <qty-input class="input-qty"
                        v-model.number="bomInfo.bomManufactureBy"
                        :isDisabled="!bomCreate"
                        placeholder="0"
                      >
                      </qty-input>
                    </span>
                    <span class="ml-2 is-pulled-left"
                      :class="!bomCreate ? 'has-text-white-ter' : 'has-text-black-bis'">
                      days before manufacture
                    </span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column" v-if="kind === 'forward' && !isCutOrder">
        <div
          class="table-container"
          :class="[$store.state.activeScreen !== 'material-edit-sourcing' ?
            'move-order-table' : '', ['mm-sourcing',
              'material-edit-sourcing'].includes($store.state.activeScreen) ?
              'sourcing-move' : '']"
          v-if="!$_.isEmpty(tableConfig)"
        >
          <div>
            <search-bar v-if="kind === 'forward'"
              placeholder="Search"
              :shouldEmit="shouldEmit"
              @search="getSearchValue"
            >
            </search-bar>
          </div>
          <div>
            <mf-table v-if="itemTableBackWard && dataFetched"
              ref="moveTable"
              :tableProps="tableConfig"
              :apiMode="false"
              :loadData="loadData"
              :checkedRows="checkedRow"
              @row-select="getSelectedRow($event)"
              @checkbox-toggled="getSelectedRow"
            >
            <template #templateSelect="{ rowData, rowField }">
              <template-select
              :value="$_.get(rowData, rowField.prop, [])"
              :rowData="rowData"
              :rowField="rowField"
              :templateProject="templateProj"
              :templateOrders="templateOrders"
              @update:value="(v) => $_.set(rowData, rowField.prop, v)"
              @select="setItemTemplate"
              >
              </template-select>
            </template>
            <template v-slot:completed="{ rowData }">
              <div >
                  {{ cards[0].manager.itemStatus[rowData._id].done ? "Yes": "No" }}
              </div>
            </template>
            </mf-table>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end"  v-if="!loadTemplateOrders">
      <button class="button is-outlined" true type="button" @click="cancel()">
        Cancel
      </button>
      <button
        class="button has-background-black-bis"
        :disabled="isDisabled"
        @click="saveAndMoveNext()"
      >
        {{ cardActionBtn }}
      </button>
    </footer>
    <transition name="slide">
      <notes-icon-slider
        v-if="isSlideNotesActive"
        :isActive="isSlideNotesActive"
        :fromMove="fromMove"
        :isPurchaseModal="fromCreatePurchase"
        :rowData="rowData"
        @close="closeNotesSlider"
        @update:note="updateNotes($event)"
      >
      </notes-icon-slider>
    </transition>
    <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
</template>

<script>
import constants from '@/components/table-cols/moveModalCols';
import BaseCardColumn from '@/components/card-edit/BaseCardColumn.vue';
import SearchBar from '@/components/SearchBar.vue';
import _ from 'lodash';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import {
  defineAsyncComponent, reactive, toRefs, computed, ref, onMounted, watch, inject, onBeforeMount,
} from 'vue';
import Projects from '@/models/Projects';
import Materials from '@/models/MaterialManager';
import { BaseOrder } from '@/models/BaseOrder';
import SupplyChain from '@/models/SupplyChain';
import Locations from '@/models/Locations';
import ProductionManager from '@/models/ProductionManager';
import ProductionTemplates from '@/models/ProductionTemplates';
import Company from '@/models/Companies';
import Users from '@/models/Users';
import Order from '@/models/Orders';
import Tasks from '@/models/Task';
import Helper from '@/models/Helper';
import Prefabs from '@/models/Prefabs';
import { DialogProgrammatic } from '@/components/Dialog';
import TemplateList from '@/components/modals/SelectTemplates.vue';
import MaterialMixin from '@/components/mixins/MaterialMixin';
import PrefabsMixin from '@/components/mixins/PrefabsMixin';
import TemplateMixin from '@/components/mixins/TemplateMixin';
import QtyInput from '@/components/fields/QtyInput.vue';
import Validations from '@/components/utils/Validations';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import MfDate from '@/components/abstract/MfDate.vue';
import TemplateSelect from '@/components/fields/TemplateSelect.vue';
import moment from 'moment';
import UtilityMixin from '../mixins/UtilityMixin';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default {
  name: 'MoveOrders',
  props: {
    isActive: {
      type: Boolean,
    },
    type: String,
    cards: Array,
    isMulti: Boolean,
    kind: String,
    templateOrders: {
      type: Array,
      default: () => [],
    },
    templateProj: {
      type: Object,
      default: () => {},
    },
    initialCard: {
      type: Object,
      default: () => {},
    },
    isCutOrder: {
      type: Boolean,
      default: false,
    },
    itemsCount: {
      type: Number,
      default: 0,
    }
  },
  emits: ['cancel', 'close'],
  components: {
    'base-column': BaseCardColumn,
    'search-bar': SearchBar,
    'mf-table': MfTable,
    'qty-input': QtyInput,
    TemplateList,
    'notes-icon-slider': NotesIconSlider,
    'mf-date': MfDate,
    'template-select': TemplateSelect,
  },
  setup({
    type, cards, isMulti, kind, initialCard, isCutOrder
  }, { emit }) {

    const store = useStore();
    const emitter = inject('emitter');
    const { moveFromPlanningToCordination, attachFormsAndTodos } = PrefabsMixin();
    const { getAllLocations } = UtilityMixin();
    const state = reactive({
      user: {},
      dataFetched: false,
      addDefaultRun: false,
      bomInfo: {
        cardId: '',
        projectId: '',
        bomManufactureBy: '',
        itemId: '',
      },
      bomCreate: false,
      cardLocation: '',
      cardConfig: {},
      companyRuns: [],
      checkedRow: [],
      totalCount: 1,
      doneCount: 0,
      defaultTemplate: {},
      selectedOrders: [],
      selectedOrder: {},
      selectedRow: [],
      selectedTemplate: null,
      listUsers: [],
      newCard: {
        __t: null,
        name: '',
        oldName: '',
        owner: {},
        stage: '',
        _customStage: '',
        _alsoNotify: null,
        notifyUsers: [],
        location: null,
        defaultRun: null,
        pTrackEnabled: false,
      },
      prefabCard: new BaseOrder({
        name: '',
        stage: 'planning',
        __t: 'Prefabs',
        project: {},
        owner: { user: null },
        location: null,
        isTrackingEnabled: false,
        defaultRun: null,
        simpleDates: {},
        manager: {
          simpleDates: {},
        },
      }),
      project: {},
      projectLocations: [],
      isLoading: false,
      showTable: false,
      showModal: false,
      shouldEmit: true,
      tableConfig: {},
      templateProject: {},
      templateProdOrders: [],
      searchText: '',
      cardTitle: 'Move Order',
      cardMessage: '',
      cardActionBtn: 'Move Order',
      requiredDates: [],
      isOneOrderPerItem: false,
      loadTemplateOrders: false,
      showMultiFieldProject: ['manager-edit-detailing', 'manager-edit-manufacturing', 'qa-edit'],
      projectName: '',
      showMultiFieldStage: [
        'prefab-edit',
        'po-edit',
        'material-edit-preparation',
        'manager-edit-manufacturing',
        'manager-edit-detailing',
        'qa-edit',
      ],
      config: {},
      prodTemplates: [],
      isSlideNotesActive: false,
      fromMove: false,
      fromCreatePurchase: false,
      rowData: {},
      refreshKitCard: false,
      reserveMaterials: false,
    });
    const toast = useToast();
    const moveTable = ref(null);
    const { setOwner, setCardOwner } = TemplateMixin();
    const {
      hasEmptyValues,
      moveFromSourcingToOrderingStage,
    } = MaterialMixin();
    const {
      createBOMForOrders,
      moveProdOrder,
    } = PrefabsMixin();

    state.user = store.state.userData;

    store.commit('setPerItem', false);
    // set data
    state.selectedOrders = cards; // for multi
    [state.selectedOrder] = cards;

    // card config based on conditions to load modal
    const constKey = `${type}-${kind}`;
    const config = constants[constKey];
    state.config = config;
    state.cardConfig = isMulti ? config.moveBasics.multiDetails
      : config.moveBasics.basicDetails;

    state.cardTitle = isMulti
      ? config.multiTitle: config.title;

    state.cardMessage = isMulti ? config.multiConfirmMsg : config.singleConfirmMsg;
    state.cardActionBtn = config.actionBtn;
    state.showTable = config.showTable;
    if (state.showTable) {
      state.tableConfig = isMulti
        ? config.orderTableBasics
        : config.tableBasics;
    }
    const isProjectPTEnabled = () => {
      const user = store.state.userData;
      const company = _.find(state.selectedOrder.project.projectSettings, {
        companyId: user.company,
      });
      if (_.isEmpty(company)) return false;
      return company.isPTEnabled;
    };

    const getSelectedItemsId = () => {
      const selectedItems = _.filter(state.selectedRow, (item) => {
        const sItems = _.find(state.selectedOrder.items, { _id: item._id });
        return sItems;
      });
      const selectedItemsId = [];
      selectedItems.forEach((item) => {
        const itemId = item._id ? item._id : item.id;
        selectedItemsId.push(itemId);
      });
      return selectedItemsId;
    };
    const getSelectedOrderId = () => {
      const selectedOrdersId = [];
      state.selectedRow.forEach((order) => {
        const orderId = order._id ? order._id : order.id;
        selectedOrdersId.push(orderId);
      });
      return selectedOrdersId;
    };

    const getTemplateName = computed(() => {
      if (!_.isEmpty(state.selectedTemplate)) {
        return state.selectedTemplate.name;
      }
      return 'No template selected';
    });
    const loadProdTemplates = async () => {
      if ((config.nextStages[0] === 'coordination' && state.selectedOrder.__t === 'Prefabs')) {
        state.prodTemplates = (await SupplyChain.supplyChain({
          projectId: state.templateProject._id,
          module: 'ProdTemplates',
          filterNoItemOrders: false,
          limit: 9999,
          page: 1,
        })).data;
      }
    };
    const fillTemplateData = async (selectedTemplate) => {
      if (_.isEmpty(selectedTemplate)) return false;

      // prefill order with the template data
      if (state.isOneOrderPerItem) {
        state.selectedTemplate = _.cloneDeep(selectedTemplate);
        // apply to selected items only
        const selectedItemsId = getSelectedItemsId();
        _.forEach(state.selectedOrder.items, (item) => {
          if (selectedItemsId.includes(item._id) && item.purpose !== 'kit') {
            item.dataFromTemplate = selectedTemplate;
          }
        });
      } else {
        // apply to entire order if not one item per order
        state.selectedTemplate = _.cloneDeep(selectedTemplate);
        state.newCard.fillBasicTemplateData(selectedTemplate);
        state.newCard.name = selectedTemplate.name;
        await setOwner(state.newCard.project, state.newCard, state.selectedTemplate);
      }
      state.loadTemplateOrders = false;
      return null;
    };

    const openApplyTempToMultiItemsModal = () => {
      // we show the template list according to the purpose of the items selected
      // if a kit item and assembly item is selected, alert the user once, to use the dropdown
      // and show the assembly order templates. do not list kit assemblies as there is
      // only one kit item and user need not use this modal to apply kit template to just a
      // single kit item, hence load assembly templates
      let isDefaultKitSelected = false;
      const defaultKitItem = _.find(state.selectedOrder.items, (tItem) => tItem.purpose === 'kit');
      if (defaultKitItem) {
        const selectedItemsId = getSelectedItemsId();
        const kitItemId = defaultKitItem._id ? defaultKitItem._id : defaultKitItem.id;
        isDefaultKitSelected = selectedItemsId.includes(kitItemId);
      }
      if (isDefaultKitSelected) {
        toast.warning(`Template not applied to ${defaultKitItem.name}. Please select a template for that item, using the item's drop-down.`);
      }
      state.loadTemplateOrders = true;
    };
    const getprojectName = computed(() => {
      const names = [];
      for (let index = 0; index < cards.length; index++) {
        const card = cards[index];
        if (names.includes(card.project.name.toLowerCase())) {
          continue;
        } else names.push(card.project.name.toLowerCase());
      }
      return names.length > 1;
    });

    const isPoSingleForward = computed(() => cards.length === 1 && constants['po-edit-forward'].nextStages[0] === 'detailing' && state.selectedOrder._customStage === 'coordination' && !_.isEmpty(cards[0].templateOrder));

    // create new card
    (async () => {
      const clonedOrder = _.cloneDeep(state.selectedOrder);
      state.newCard = Object.assign(clonedOrder, state.newCard);
      state.companyRuns = await Company.getCompanyRuns('companyRuns');

      // set card __t
      if (state.selectedOrder.isMM()) state.newCard.__t = 'Materials';
      else if (state.selectedOrder.isSourcing()) state.newCard.__t = 'Sourcing';
      else state.newCard.__t = 'ProductionOrder';

      // bom info
      state.bomInfo.cardId = state.selectedOrder.isPrefab() ? state.selectedOrder._id : '';
      state.bomInfo.projectId = state.selectedOrder.project._id;

      // set the oldname
      state.newCard.oldName = state.selectedOrder.name;
      // set the name
      state.newCard.name = state.isOneOrderPerItem ? 'Item name will become order name' : '';

      [state.newCard.stage] = config.nextStages;

      [state.project, state.user] = await Promise.all([
        Projects.getOne(state.selectedOrder.project._id),
        store.state.userPromise,
      ]);

      const companyIds = [state.selectedOrder.owner.company._id];

      if (!['detailing', 'manufacturing', 'qa'].includes(state.newCard.stage)) {
        companyIds.push(..._.map(state.project.projectCompanies, '_id'));
      }

      state.listUsers = await Users.getCompanyProjectAllLinkedUsers({
        params: {
          companyIds,
          projectId: state.selectedOrder.project._id,
          ignoreCompany: true,
        },
      });

      const oldManagerOwner = state.selectedOrder.isPM()
        ? state.selectedOrder.manager.owner : state.selectedOrder.owner;

      state.newCard.owner._oldUser = _.clone(state.selectedOrder.owner.user);
      state.newCard.owner.user = _.find(state.listUsers, { _id: oldManagerOwner.user._id });

      const defaults = state.project.getDefaults(state.newCard, config.nextStages[0]);

      state.newCard.owner.user = defaults.newOwner
      || _.find(state.listUsers, { _id: oldManagerOwner.user._id });

      state.newCard._alsoNotify = defaults.notify || null;

      await store.getters.userPromise;
      state.user = store.state.userData;

      const projectSetting = _.find(
        state.project.projectSettings,
        { companyId: state.user.company },
      );

      if (projectSetting) {
        state.addDefaultRun = projectSetting.addDefaultRun;
      }

      const projLocations = await getAllLocations(state.project._id, true);

      let loc = state.selectedOrder.location;
      if (state.selectedOrder.isPM()) { loc = _.get(state.selectedOrder, 'manager.location'); }
      if (state.selectedOrder.isMM() || state.selectedOrder.isSourcing()) { loc = _.get(state.selectedOrder, 'baseDelivery.location'); }

      let templateLoc = null;
      if (isPoSingleForward.value) {
        const appliedTemplate = _.find(
          state.prodTemplates,
          (temp) => temp._id.toString() === cards[0].templateOrder.toString(),
        );
        if (appliedTemplate) {
          const { location } = appliedTemplate.manager;
          if (!_.isEmpty(location)) templateLoc = location;
        }
      }
      if ((isPoSingleForward.value || !cards.length) && templateLoc) {
        _.set(state.newCard, 'location', templateLoc);
      }
      if (!state.newCard.location) {
        state.newCard.location = defaults.newLocation
        || _.find(projLocations, { _id: loc ? loc._id : null });
      }

      state.projectLocations = Locations.groupLocations(projLocations, store.state.companyData);

      state.isLoading = false;

      if (state.selectedOrder.stage === 'coordination') {
        state.newCard.pTrackEnabled = isProjectPTEnabled() && !state.selectedOrder.multiTrade.value;
      }
      if (state.newCard.pTrackEnabled) [state.newCard.defaultRun] = state.companyRuns;
      if (state.selectedOrder._customStage === 'planning') {
        if (state.selectedOrder.purpose === 'kit') {
          await loadProdTemplates();
          const kitTemplate = _.find(state.prodTemplates, { isDefault: true, purpose: _.get(state.selectedOrder, 'purpose', null) });
          state.defaultTemplate = kitTemplate;
        }
        fillTemplateData(state.defaultTemplate);
      }
      if (cards.length > 1) {
        state.projectName = getprojectName.value ? '(Multiple)' : cards[0].project.name;
      }
      state.isLoading = false;
      return true;
    })();

    const isPrefabMultiForward = () => state.selectedOrder._customStage === 'planning'
        && cards.length > 1
        && config.nextStages[0] === 'coordination';

    const isPoMultipleForward = () => state.selectedOrder._customStage === 'coordination'
        && cards.length > 1
        && config.nextStages[0] === 'detailing';

    const isDetailMultiForward = () => state.selectedOrder._customStage === 'detailing'
        && cards.length > 1
        && config.nextStages[0] === 'manufacturing';

    const isManuMultiForward = () => state.selectedOrder._customStage === 'manufacturing'
        && cards.length > 1
        && config.nextStages[0] === 'qa';

    const isRequestingMultiForward = () => state.selectedOrder._customStage === 'preparation'
      && cards.length > 1
      && config.nextStages[0] === 'sourcing';

    const isSrcingMultiForward = () => state.selectedOrder._customStage === 'sourcing'
      && cards.length > 1
      && config.nextStages[0] === 'ordering';

    const filteredItems = () => {
      if (state.selectedOrder.manager && state.selectedOrder._customStage === 'manufacturing') {
        return state.selectedOrder.items.filter((item) => item.stage === config.currentStage && state.selectedOrder.manager.itemStatus[item._id].done);
      }
      if (state.selectedOrder._customStage === 'preparation') {
        return state.selectedOrder.items.filter((item) => item.stage === config.currentStage && (!_.has(item, 'purchase') || item?.purchase));
      }
      return state.selectedOrder.items.filter((item) => {
        item.dataFromTemplate = '';
        return item.stage === config.currentStage;
      });
    };

    const toggleBomCheckbox = async () => {
      if (state.bomCreate) {
        try {
          await state.selectedOrder.BOMQuantityCheck();
        } catch (e) {
          console.log(e);
          toast.warning(e?.message);
          emit('close', false);
        }
      }
    };
    const prefabDatefill = async (updatePrefab) => {
      // if planning -> cord
      if (config.nextStages[0] === 'coordination' && state.selectedOrder.__t === 'Prefabs') {
        if (updatePrefab && !_.isEmpty(initialCard)) {
          // if the dates value is empty the saveCard call in saveAndMoveNext function in
          // cardFooter.vue which makes dates and simpledates as empty.
          const { dates, simpleDates } = _.pick(initialCard, ['dates', 'simpleDates']);
          _.set(state.selectedOrder, 'dates', dates);
          _.set(state.selectedOrder, 'simpleDates', simpleDates);
        }
        for (const date of state.selectedOrder.dates) {
          if (date.kind === 'coord' && !date.value) {
            date.value = state.newCard.simpleDates.coord.value;
          }
          if (date.kind === 'deliver' && !date.value) {
            date.value = state.newCard.simpleDates.deliver.value;
          }
        }
        if (updatePrefab) {
          state.selectedOrder.updateBeforeMove = true;
          await Prefabs.update(state.selectedOrder);
        }
      }
    };

    const getSelectedRow = (row) => {
      state.selectedRow = row;
    };

    const getSearchValue = (searchText) => {
      state.searchText = searchText;
      moveTable.value.refreshTable();
    };

    const cancel = () => {
      emit('refresh-order');
      emit('close');
    };

    const loadTemplates = (() => {
      state.loadTemplateOrders = true;
    });

    const getPermitToAdd = () => {
      _.some(state.newCard.items, (i) => {
        if (!_.isEmpty(i.dataFromTemplate) && i.dataFromTemplate.items
        && i.dataFromTemplate.items.length > store.state.itemLimits.po) {
          return true;
        }
        return false;
      });
    };

    const createTemplatePrefab = async () => {
      let prefabCard = _.cloneDeep(state.prefabCard);
      prefabCard.project = state.project;
      prefabCard.owner = _.cloneDeep(state.newCard.owner);
      prefabCard.owner.company = state.selectedTemplate.owner.company;
      prefabCard.addOrUpdateDate('coord', state.newCard.simpleDates.coord.value);
      prefabCard.addOrUpdateDate('deliver', state.newCard.simpleDates.deliver.value);
      prefabCard.fillBasicTemplateData(state.selectedTemplate);
      try {
        prefabCard.stage = 'coordination';
        prefabCard = await ProductionManager.createOrderFromTemplate(prefabCard);
      } catch (e) {
        console.log('error', e);
      }
      return prefabCard;
    };

    const itemTableBackWard = computed(() => !(kind === 'backward' && ['manufacturing',
      'detailing', 'coordination'].includes(state.selectedOrder._customStage)));

    const loadData = () => {
      let fetchedData;

      if (!isMulti) {
        if (type === 'material-edit-sourcing') {
          fetchedData = state.selectedOrders;
        } else {
          fetchedData = filteredItems();
        }
      } else {
        // if isMulti
        fetchedData = state.selectedOrders;
      }

      // hackyfix alert: setting isEditing to true due to mftable setting
      // props.row.isEditing to true by default
      // (the dropdowns do not work otherwise)
      fetchedData = fetchedData.map((item) => {
        item.isEditing = type === 'material-edit-sourcing';
        return item;
      });

      // search
      if (fetchedData.length > 0 && state.searchText) {
        fetchedData = _.filter(fetchedData,
          (order) => order.name.toLowerCase().includes(state.searchText.toLowerCase()));
      }

      // select all rows by default
      state.checkedRow = fetchedData;
      state.selectedRow = fetchedData;
      return fetchedData;
    };

    const onePerItemChanged = () => {
      store.commit('setPerItem', state.isOneOrderPerItem);
      state.newCard.name = state.isOneOrderPerItem ? 'Item name will become order name' : '';
      moveTable.value.refreshTable();
      state.selectedTemplate = {};
    };

    const getProjectUsersList = async (project) => {
      const result = await Projects.listProjectUsers({
        project_id: project._id,
        limit: 1000,
        sort: 'name',
        listActivatedUsers: true,
      });
      return result.data;
    };

    const validateTask = async (cardsToSave) => {
      const materialsData = [];
      for (const card of cardsToSave) {
        const { data } = await Helper.getMaterialItems(card);
        materialsData.push(...data);
      }
      if (!_.isEmpty(materialsData)) return Validations.validateTask(materialsData);
      return true;
    };

    const onSave = async (cardToSave) => {
      // planning -> cord
      if (config.currentStage === 'planning') return moveFromPlanningToCordination(cardToSave);

      // cord -> detailing
      if (state.selectedOrder.stage === 'coordination') {
         // if bom is not created, and "Create BOM option is checked"
        //  also checking if the card of items with catId 
        function cardHaveItemsWithCatId(card) {
          !!card.items.findIndex(item => !_.isEmpty(item.catId));
        }
        if (!cardToSave._bomCreated && cardToSave._bomTasks?.create && cardHaveItemsWithCatId(cardToSave)) {
          cardToSave = await createBOMForOrders(cardToSave);
        }
        const materialsToSubmit = await cardToSave.getSubmitMaterials();
        if (_.some(materialsToSubmit, { stage: 'preparation' }) && cardToSave._bomTasks?.submit) {
          await cardToSave.submitMaterials(materialsToSubmit, cardToSave);
        }
        return ProductionManager.createFromPO({ card: cardToSave });
      }

      if (kind === 'backward' && cardToSave.__t === 'ProductionOrder') {
        return moveProdOrder(cardToSave);
      }

      // preparation -> sourcing
      if (cardToSave.isMM()) {
        if (!isMulti) cardToSave.name = cardToSave.oldName;
        return Materials.pullToSourcing([cardToSave._id], cardToSave);
      }

      cardToSave.projectId = cardToSave.project._id;

      // sorucing -> ordering
      if (state.selectedOrder.stage === 'sourcing'
        || _.some(state.selectedOrder.items, (item) => item.stage === 'sourcing')
        || (cardToSave.stage === 'sourcing' && cardToSave.fromSchedule)
      ) {
        let filterdOrder = _.filter(state.selectedOrders, {_id: cardToSave._id});
        await moveFromSourcingToOrderingStage(_.castArray(filterdOrder));
      }
      // detailing -> manufacturing
      if (['detailing', 'coordination'].includes(state.selectedOrder.stage) || _.some(state.selectedOrder.items, (item) => item.stage === 'detailing')) {
        // if bom is not created, and "Create BOM option is checked"
        if ((!cardToSave._bomCreated && cardToSave._bomTasks?.create) || cardToSave.isCutOrder) {
          cardToSave = await createBOMForOrders(cardToSave);
        }

        if(!cardToSave.isCutOrder){
          const materialsToSubmit = await cardToSave.getSubmitMaterials();
          if (_.some(materialsToSubmit, { stage: 'preparation' }) && cardToSave._bomTasks?.submit) {
            await cardToSave.submitMaterials(materialsToSubmit, cardToSave);
          }
        }

        let pickReqOnSite = cardToSave.getDate('pickReqOnSite');
        if (_.isEmpty(pickReqOnSite)) {
          pickReqOnSite = cardToSave.getDate('manufactureBy');
        }
        cardToSave.dates.splice(_.findIndex(cardToSave.dates, (date) => date.kind === 'pickReqOnSite'), 0);
        const newCard = await moveProdOrder(cardToSave);

        // Transfer request
        if (cardToSave._createTR && pickReqOnSite) {
          const task = {};
          task.type = 'pick';
          task.assignee = {
            user: { _id: state.user._id, name: state.user.name },
            company: { _id: state.user.company, name: state.user.companyName },
          };
          _.assign(task, {
            createdFor: { _id: cardToSave._id },
            dueDate: pickReqOnSite,
            project: cardToSave.project,
            destinationLocation: cardToSave.manager.location,
          });
          await Tasks.create(task);
          toast.success('Transfer Request Created');
        } else if(!cardToSave.isCutOrder || cardToSave?._bomTasks?.reserveMaterials){
          const list = await Helper.getMaterialItems(newCard);
          const { data } = list;
          const reqCatIds = cardToSave.reqForReserveQty(data, state.user);
          await cardToSave.reserveCatalog(reqCatIds);
        }
        return newCard;
      }

      // manufacturing -> qa
      if (state.selectedOrder.stage === 'manufacturing' || _.some(state.selectedOrder.items, (item) => item.stage === 'manufacturing')) {
        if (cardToSave.submit && !_.isEmpty(cardToSave.materials)) {
          await cardToSave.submitMaterials(cardToSave.materials);
        }
        return moveProdOrder(cardToSave);
      }
      return true;
    };

    const makeMove = async (cardsToSave) => {
      // this.showProgressBar = true;
      state.isLoading = true;
      state.totalCount = cardsToSave.length;
      if (kind === 'forward'
        && _.every(cardsToSave, (card) => card._customStage === 'detailing')
        && _.some(cardsToSave, (card) => _.get(card, 'createTR', false))) {
        const validated = await validateTask(cardsToSave);
        if (!validated) {
          const err = 'Some of the items doesnt have Catalog ID. Please add Catalog ID before creating Task request!';
          toast.error(`${err}`);
          state.isLoading = false;
          emit('close', false);
          return false;
        }
      }
      for await (const card of cardsToSave) {
        try {
          const order = await onSave(card);
          let materials = [];
          for (const itm of card.items) {
            if (state.bomCreate) {
              state.bomInfo.cardId = _.get(card, 'id', null);
              state.bomInfo.projectId = _.get(card, 'project._id', null);
              state.bomInfo.itemId = _.get(itm, '_id', null);
              const BOM = await Materials.createBOM(state.bomInfo);
              materials = _.concat(materials, BOM);
            }
          }
          if (materials.length > 0 && !_.isEmpty(order._id)) {
            const bom = materials.filter((m) => card.items.some((i) => i._id === m.linkedAssembly));
            if (!_.isEmpty(bom)) {
              const materialIds = _.castArray(bom).map((b) => b._id);
              await Order.addMaterials(order, materialIds);
            }
          }
        } catch (e) {
          emit('close', false);
          console.log('error', e);
          return toast.error(_.get(e, 'data.err', false) || e.data || 'An unexpected error occurred');
        } finally {
          if (store.state.activeScreen !== 'kit-view-edit') {
            emit('refresh-table');
          } else {
            state.refreshKitCard = true;
          }
          emit(cards[0].__t === 'Materials' ? 'refresh-order' : 'reload-card');
        }
        state.doneCount += 1;
      }
      if (store.state.activeScreen === 'kit-view-edit' && state.refreshKitCard) {
        emit('refresh-table');
      }
      if (type === 'material-edit-preparation') emit('reload-card');
      toast.success(state.doneCount === 1 ? 'One Order moved' : `Only ${state.doneCount} Orders Moved`);
      emit('close', true);
      return true;
    };

    const prepareToMove = async () => {
      // the commented out code is the example on how to default it to all users.
      // const notifyUsers = this.newCard._alsoNotify && this.newCard._alsoNotify.length ?
      //   this.newCard._alsoNotify : this.listUsers;
      state.newCard.notifyUsers = _.map(state.newCard._alsoNotify || [], '_id');
      const projectUsersList = await getProjectUsersList(state.newCard.project);

      if (kind === 'backward' && state.selectedOrders.length === 1) {
        state.newCard.items = _.get(state.selectedOrders[0], 'items', '');
      }
      if (_.isEmpty(state.newCard.name)) { state.newCard.name = state.selectedOrder.name; }
      if (_.isEmpty(state.newCard._customStage)) { state.newCard._customStage = state.selectedOrder._customStage; }

      if (state.newCard.isPM()) {
        state.newCard.manager.owner.user = _.clone(state.newCard.owner.user);
        state.newCard.manager.location = state.newCard.location;
        state.newCard.manager._oldLocation = _.clone(state.selectedOrder.manager.location);
      } else if (state.newCard.isMM()) {
        state.newCard.baseDelivery.location = state.newCard.location;
      }

      let cardsToSave = [state.newCard];
      cardsToSave = type === 'material-edit-sourcing' ? state.selectedOrders : cardsToSave;

      // if not one order per item and prefab card
      if (!state.isOneOrderPerItem && (state.selectedOrder.isKit()
         && state.selectedOrder.isPrefab())) {
        cardsToSave = [];

        const { items } = state.newCard;

        for (const purpose of ['kit', 'assembly']) {
          const cardItems = items.filter((item) => item.purpose === purpose);
          if (cardItems.length === 0) continue;
          let newCard = _.cloneDeep(state.newCard);
          const [i] = cardItems;
          const newCardName = newCard.name;
          if (cardItems.every((item) => item.level === i.level && item.zone === i.zone)) {
            ({ level: newCard.level, zone: newCard.zone } = i);
          }
          newCard.items = cardItems;

          if (!_.isEmpty(i.dataFromTemplate)) {
            newCard.fillBasicTemplateData(i.dataFromTemplate);
            newCard.fillDetailedTemplateData(i.dataFromTemplate, 'deliver', false);
            const { project } = state.newCard;
            const res = await setCardOwner(project, newCard, i.dataFromTemplate);
            if (res.msg) {
              toast.error(res.msg);
            }
            newCard.addTemplateTodos(i.dataFromTemplate.todos, state.user);
            attachFormsAndTodos(newCard, i.dataFromTemplate);
          }
          newCard.name = purpose === 'kit' ? i.name : newCardName;
          newCard.purpose = purpose;
          if (newCard.isAssembly() && _.isEmpty(newCard.templateOrder)) {
            const defaults = state.project.getDefaults(newCard, config.nextStages[0]);
            if (defaults.newOwner) newCard.owner.user = defaults.newOwner;
          }
          newCard = new BaseOrder(Object.assign(newCard.toJSON(), {
            _id: '',
            __t: 'ProductionOrder',
          }));
          newCard.makeDateDirty = _.get(i, 'makeDateDirty', true);
          cardsToSave.push(newCard);
        }
      } else if (state.isOneOrderPerItem || (state.newCard.isKit() && state.newCard.isPrefab())) {
        // if prefab card and one order per item is checked
        const itemPromises = state.newCard.items.map(async (i) => {
          if (state.newCard.isKit() && ['general'].indexOf(i.purpose) > -1) {
            return false;
          }

          let newCard = _.cloneDeep(state.newCard);
          /* This check is required to ensure that item dates are only used when both of them
            are available, else use fallback dates (dates entered in modal) */
          if (_.every(['coord', 'deliver'], (date) => _.get(i.simpleDates, `${date}.value`, false) || false)) {
            // Copy item level dates into order
            ['coord', 'deliver'].forEach((dateKind) => {
              newCard.simpleDates[dateKind].value = i.simpleDates[dateKind].value;
            });
          }

          newCard.financialId = i.catId;
          ({ level: newCard.level, zone: newCard.zone } = i);
          if (!_.isEmpty(i.dataFromTemplate)) {
            newCard.fillBasicTemplateData(i.dataFromTemplate, true);
          } else {
            newCard.fillBasicTemplateData(i.dataFromTemplate);
          }
          newCard.fillDetailedTemplateData(i.dataFromTemplate, 'deliver', false);
          const res = await setCardOwner(state.newCard.project,
            newCard, i.dataFromTemplate, projectUsersList);
          if (res.msg) {
            toast.error(res.msg);
          }

          newCard.items = [i];
          newCard.name = i.name;
          if (!_.isEmpty(i.dataFromTemplate)) {
            newCard.addTemplateTodos(i.dataFromTemplate.todos, state.user);
            attachFormsAndTodos(newCard, i.dataFromTemplate);
          }
          newCard = new BaseOrder(Object.assign(newCard.toJSON(), {
            _id: '',
            __t: 'ProductionOrder',
          }));

          if (state.newCard.isKit()) {
            newCard.purpose = i.purpose;
            // setting makeDirty to true default for Kit and Assembly
            newCard.makeDateDirty = _.get(i, 'makeDateDirty', true);
          }

          if (newCard.isAssembly() && _.isEmpty(newCard.templateOrder)) {
            const defaults = state.project.getDefaults(newCard, config.nextStages[0]);
            if (defaults.newOwner) newCard.owner.user = defaults.newOwner;
          }
          return newCard;
        });
        cardsToSave = await Promise.all(itemPromises);
      } else if (cards.length > 1) {
        // multi move orders
        let selectedCards = state.selectedOrders;

        if (isRequestingMultiForward()) {
          const selectedOrdersId = getSelectedOrderId();
          selectedCards = _.filter(selectedCards, (c) => selectedOrdersId.includes(c._id));
        }

        // multi orders
        if (_.some(cardsToSave, { fromSchedule: true })) {
          await makeMove(cardsToSave);
          return;
        }
        cardsToSave = selectedCards.map((i) => {
          let newCard = _.cloneDeep(state.newCard);

          Object.assign(newCard, _.pick(i, ['name', '_id', 'id', 'items', 'project', 'templateOrder', 'purpose', 'materials', 'customId', '_bomCreated', '_bomSubmitted', '_bomTasks']));

          if (!isSrcingMultiForward()) {
            if (i.templateOrder) {
              const templateProdOrder = _.find(state.templateProdOrders, { _id: i.templateOrder });
              const { companyRuns } = state;
              newCard = ProductionManager.getTemplateRuns({
                newCard, templateProdOrder, companyRuns,
              });
              newCard.pTrackEnabled = true;
              newCard.addTemplateTodos(_.get(templateProdOrder, 'todos', []), state.user);
              attachFormsAndTodos(newCard, templateProdOrder);
            } else {
              [newCard.defaultRun] = state.companyRuns;
              newCard.pTrackEnabled = true;
            }
          }

          // prefab -> po
          if (isPrefabMultiForward()) {
            newCard.__t = 'ProductionOrder';
          }

          // coord -> detailing
          if (isPoMultipleForward()) {
            newCard.addOrUpdateDate('poDetailBy', i.simpleDates.poDetailBy.value);
            newCard.addOrUpdateDate('deliver', i.simpleDates.deliver.value);
            newCard.currentNote = i.currentNote;
          }

          if (isDetailMultiForward()) {
            newCard.addOrUpdateDate('manufactureBy', _.get(i, 'manager.simpleDates.manufactureBy.value', ''));
          }

          const defaults = state.project.getDefaults(newCard, config.nextStages[0]);

          // owner
          if (state.newCard.owner.user.name === '(Defaults)') {
            if (!_.isEmpty(defaults.newOwner)) newCard.owner.user = defaults.newOwner;
            else newCard.owner.user = i.owner.user;
          }
          // location
          if (!isPrefabMultiForward() && !isDetailMultiForward() && state.newCard.location.name === '(Defaults)' && !_.isEmpty(defaults.newLocation)) {
            newCard.location = defaults.newLocation;
          }
          // notify users
          if (state.newCard._alsoNotify) {
            newCard._alsoNotify = [state.newCard._alsoNotify];
            newCard.notifyUsers = _.map(state.newCard._alsoNotify, '_id');
          }
          if (isManuMultiForward()) {
            newCard.addOrUpdateDate('qaBy', i.manager.simpleDates.qaBy.value);
          }

          return newCard;
        });
      } else if (!_.isEmpty(cardsToSave[0].templateOrder)
         || _.some(state.newCard.items, (i) => (!_.isEmpty(i.dataFromTemplate)))) {
        // to fill the template data
        let newCard = cardsToSave[0];
        let templateProdOrder = state.selectedTemplate;
        if (_.isEmpty(state.selectedTemplate)) {
          // eslint-disable-next-line
          templateProdOrder = state.templateProdOrders[0];
        }
        if (newCard.stage === 'coordination') {
          if (!newCard.templateOrder) {
            if (!state.selectedTemplate && !state.isOneOrderPerItem) {
              // if isOneOrderPerItem is false, apply default template
              templateProdOrder = state.defaultTemplate;
            }
            newCard.fillBasicTemplateData(templateProdOrder);
          }
          newCard.fillDetailedTemplateData(templateProdOrder, 'deliver', false);
          newCard.fillDetailedTemplateData(templateProdOrder, 'manufactureBy', false);
        }
        if (!_.isEmpty(templateProdOrder)) {
          attachFormsAndTodos(newCard, templateProdOrder);
          const { companyRuns } = state;
          newCard = ProductionManager.getTemplateRuns({ newCard, templateProdOrder, companyRuns });
          newCard.pTrackEnabled = true;
          newCard.addTemplateTodos(_.get(templateProdOrder, 'todos', []), state.user);
        }
      } else if (_.isEmpty(cardsToSave[0].templateOrder)) {
        [state.newCard] = cardsToSave;
        [state.newCard.defaultRun] = state.companyRuns;
        state.newCard.pTrackEnabled = true;
      }

      if (state.newCard.isKit() && state.selectedOrder.isPrefab()) {
        // check already PO Kit created
        let kitOrder = {};
        const [kitItem] = state.selectedOrder.items.filter((i) => ((i.purpose === 'kit')));
        if (_.get(kitItem, 'status', '') === 'NotUsed') {
          const kitCard = _.cloneDeep(state.newCard);
          for (const card of cardsToSave) {
            if (card && card.purpose === 'kit') {
              kitOrder = card;
              card.items.push(...kitCard.items.filter((i) => ((['general'].indexOf(i.purpose) > -1))));
            }

            if (card && card.purpose === 'assembly' && !_.isEmpty(kitOrder.templateOrder)
              && _.isEmpty(card.templateOrder)) {
              card.simpleDates = _.cloneDeep(_.get(kitOrder, 'simpleDates', {}));
              card.makeDateDirty = true;
            }
          }
        }
      }
      cardsToSave = cardsToSave.filter((obj) => (obj !== false));
      await makeMove(cardsToSave);
    };

    const addTemplate = async (add = true) => {
      state.isLoading = true;
      state.totalCount = 1;
      state.doneCount = 0;

      if (state.isOneOrderPerItem) {
        state.totalCount = state.newCard.items.length;
        // create PO order for each item consisting of all template data including it's items
        for (const i of state.newCard.items) {
          let templatePO = {};
          state.selectedTemplate = i.dataFromTemplate;
          if (!_.isUndefined(i.dataFromTemplate) && i.dataFromTemplate?.items?.length > 0) {
            templatePO = await createTemplatePrefab();
          }
          const newCard = _.cloneDeep(state.newCard);
          newCard.items = [i];
          newCard.name = i.name;
          let newPO = new BaseOrder(Object.assign(newCard.toJSON(), {
            _id: '',
            __t: 'ProductionOrder',
          }));
          if (!_.isUndefined(i.dataFromTemplate)) {
            newPO.templateOrder = state.selectedTemplate._id;
            newPO = newPO.fillDetailedTemplateData(state.selectedTemplate, 'deliver', false);
            await setOwner(state.newCard.project, newPO, i.dataFromTemplate);
          }
          newPO.name = newCard.name;
          newPO = await newPO.save();
          const combineObj = {};
          if (!_.isEmpty(templatePO)) {
            combineObj.sourceId = templatePO._id;
            combineObj.destId = newPO._id;
            combineObj.projectId = state.project._id;
            combineObj.name = newPO.name;
            await Order.combine(combineObj);
            state.doneCount += 1;
          }
        }
      } else if (isPrefabMultiForward()) {
        // multi move -> prefab to code -> add template data
        for (let i = 0; i < state.selectedOrders.length; i++) {
          let templatePO;
          state.selectedTemplate = state.selectedOrders[i].dataFromTemplate;

          const itemsExistsCheck = add && (_.get(state.selectedTemplate, 'items', [])).length > 0;

          // create a link prefab for template items
          if (state.selectedTemplate) {
            // if template is selected
            if (itemsExistsCheck) {
              const prefabCard = _.cloneDeep(state.prefabCard);
              prefabCard.project = state.project;
              prefabCard.owner = _.cloneDeep(state.newCard.owner);
              prefabCard.owner.company = state.selectedOrders[i].owner.company;
              prefabCard._alsoNotify = state.newCard._alsoNotify;
              prefabCard.addOrUpdateDate('coord', state.selectedOrders[i].simpleDates.coord.value);
              prefabCard.addOrUpdateDate('deliver', state.selectedOrders[i].simpleDates.deliver.value);
              prefabCard.fillBasicTemplateData(state.selectedTemplate);
              try {
                prefabCard.stage = 'coordination';
                templatePO = await ProductionManager.createOrderFromTemplate(prefabCard);
              } catch (e) {
                console.log('error', e);
              }
              const newCard = _.cloneDeep(state.selectedOrders[i]);
              let newPO = new BaseOrder(Object.assign(newCard.toJSON(), {
                _id: '',
                __t: 'ProductionOrder',
              }));
              newPO = newPO.fillDetailedTemplateData(state.selectedTemplate, 'deliver', false);
              newPO.name = newCard.name;
              newPO = await newPO.save();
              if (itemsExistsCheck) {
                // combine
                const combineObj = {};
                combineObj.sourceId = templatePO._id;
                combineObj.destId = newPO._id;
                combineObj.projectId = state.project._id;
                combineObj.name = newPO.name;
                await Order.combine(combineObj);
              }
            } else {
              const newCard = _.cloneDeep(state.selectedOrders[i]);
              let newPO = new BaseOrder(Object.assign(newCard.toJSON(), {
                _id: '',
                __t: 'ProductionOrder',
              }));
              newPO = newPO.fillDetailedTemplateData(state.selectedTemplate, 'deliver', false);
              // newPO.name = newCard.name;
              newPO = await onSave(newPO);
            }
          } else {
            // if template is not selected
            const newCard = _.cloneDeep(state.selectedOrders[i]);
            let newPO = new BaseOrder(Object.assign(newCard.toJSON(), {
              _id: '',
              __t: 'ProductionOrder',
            }));
            newPO.name = newCard.name;
            newPO = await newPO.save();
          }
          state.doneCount += 1;
        }
      } else {
        // create a link prefab for template items
        const templatePO = await createTemplatePrefab();
        const newCard = _.cloneDeep(state.newCard);
        let newPO = new BaseOrder(Object.assign(newCard.toJSON(), {
          _id: '',
          __t: 'ProductionOrder',
        }));
        newPO = newPO.fillDetailedTemplateData(state.selectedTemplate, 'deliver', false);

        newPO.name = newCard.name;
        newPO = await newPO.save();
        const combineObj = {};
        combineObj.sourceId = templatePO._id;
        combineObj.destId = newPO._id;
        combineObj.projectId = state.project._id;
        combineObj.name = newPO.name;
        await Order.combine(combineObj);
        state.doneCount += 1;
      }
      toast.success(`Only ${state.doneCount} Order(s) Moved!`);
      emit('close', true);
      emit('refresh-table');
    };

    const addTemplateItems = (add) => {
      if (add) {
        // if planning and one order per item
        if (!isPrefabMultiForward && state.selectedOrder.stage === 'planning'
        && ((state.isOneOrderPerItem && getPermitToAdd())
        || (!_.isEmpty(state.selectedTemplate)
        && (state.newCard.items.length + state.selectedTemplate.items.length)
        > store.state.itemLimits.po))) {
          return toast.error('Number of items is limited to 50 per order.');
        }
        return addTemplate(add);
      }
      if (!add && isPrefabMultiForward()) {
        return addTemplate(add);
      }
      return prepareToMove();
    };

    async function updateOrderTemplates(selectedTemplate) {
      if (_.isEmpty(selectedTemplate)) return false;
      // apply templates to all orders
      state.selectedTemplate = _.cloneDeep(selectedTemplate);

      if (isMulti) {
        // set all the orders template with the selected template
        _.forEach(state.selectedOrders, (order) => {
          order.templateOrder = state.selectedTemplate._id;
          order.dataFromTemplate = state.selectedTemplate;
        });
      }
      return null;
    }

    const confirmAddTemplateItems = () => {
      // fill dates if template is selected
      prefabDatefill(true);
      // dialog for asking if add existing template items
      if (isPrefabMultiForward()) {
        // to check selected template have items
        const tempItemCheck = _.some(state.selectedOrders, (order) => (
          !_.isEmpty(order.dataFromTemplate) && order.dataFromTemplate.items
        && order.dataFromTemplate.items.length > 0));
        if (!tempItemCheck) return addTemplate(false);
      }
      const confirmParam = {
        message: 'The Template has pre-defined items. Add them to your Production Order?',
        okButton: 'Add',
        additionalButton: 'Do Not Add',
        onConfirm: () => {
          addTemplateItems(true);
        },
        additionalMethod: () => {
          addTemplateItems(false);
        },
      };
      return DialogProgrammatic.confirm(confirmParam);
    };

    const saveAndMoveNext = _.debounce(async () => {
      try {
        if (config.pickName && state.newCard.name.trim().length === 0 && !isMulti) {
          return toast.error('Please enter the order name');
        }

        if (config.pickName && state.newCard.name.trim().length <= 2 && !isMulti) {
          return toast.error('Order Name must be at least 3 charachters');
        }

        if (_.isEmpty(state.newCard.owner.user)) {
          return toast.error('Please select a new Owner');
        }

        if (!_.some(cards, ['stage', 'planning']) && state.newCard.pTrackEnabled
         && _.isEmpty(state.newCard.defaultRun) && !state.addDefaultRun) {
          return toast.error('Please select a Run');
        }

        if (config.pickLocation && !state.newCard.location && !isMulti) {
          return toast.error('Please select a Location');
        }

        if (cards.length > 1 && config.instantMulti) {
          return makeMove(cards.map((card) => {
            card = _.cloneDeep(card);
            [card.stage] = config.nextStages;
            const newLocation = _.get(state.newCard, 'location', false);
            if (newLocation) _.set(card, 'location', newLocation);
            const newOwner = _.get(state.newCard, 'owner.user', false);
            if (newOwner) _.set(card, 'owner.user', newOwner);
            if (state.newCard._alsoNotify) {
              card._alsoNotify = [state.newCard._alsoNotify];
              card.notifyUsers = [card._alsoNotify[0]._id];
            }
            const newProject = store.getters.findProject({ _id: card.project._id });
            const defaults = newProject.getDefaults(card, config.nextStages[0]);
            if (!_.isEmpty(defaults.newOwner)) card.manager.owner.user = defaults.newOwner;
            if (!_.isEmpty(defaults.newLocation)) {
              card.location = defaults.newLocation;
              card.manager.location = defaults.newLocation;
            }
            if (!_.isEmpty(defaults.notify)) {
              card.notifyUsers = _.map(defaults.notify, '_id');
            }
            return card;
          }));
        }

        const selectedItemsId = getSelectedItemsId();

        if (!isMulti && !isPrefabMultiForward()
            && !isPoMultipleForward()
            && !isManuMultiForward()
            && !isRequestingMultiForward()
            && state.selectedOrder.stage !== 'sourcing') {
          const filteredItemsArr = _.cloneDeep(
            state.selectedOrder.items.filter((item) => selectedItemsId.includes(item._id)),
          );

          state.newCard.items = filteredItemsArr;
          if (state.newCard.items.length === 0 && !_.isEmpty(state.tableConfig) && kind === 'forward' && !isCutOrder) {
            return toast.error('Please select at least one item');
          }
          if (state.newCard.items.length > 50) {
            return toast.error('Please select less than 50 items to move');
          }
        }

        if (!_.isEmpty(state.templateProject)) {
          const params = {
            projectId: state.templateProject._id,
            module: cards[0].__t === 'Materials' ? 'MatTemplates' : 'ProdTemplates',
            filterNoItemOrders: false,
            orderId: _.compact(_.map(cards, 'templateOrder')),
            getArchived: true,
            limit: 9999,
            page: 1,
          };

          state.isLoading = true;
          state.templateProdOrders = (await SupplyChain.supplyChain(params)).data;
          state.isLoading = false;

          if (state.selectedOrder.stage === 'coordination' && (!isProjectPTEnabled())) {
            for (const card of state.selectedOrders) {
              const templateProdOrder = _.find(
                state.templateProdOrders,
                { _id: card.templateOrder },
              );
              if (card.templateOrder && templateProdOrder) {
                if (!templateProdOrder.manager.runs.length) {
                  return toast.error(`"Neither the template nor the Project settings has runs defined.
                  Please add at least one run to template and then try"`);
                }
              } else if (!_.isEmpty(state.defaultTemplate
              && state.defaultTemplate.manager && state.defaultTemplate.manager.runs
              && !state.defaultTemplate.manager.runs.length)) {
                return toast.error(`"Neither the Default template nor the Project settings has runs defined.
                  Please add at least one run to template and then try"`);
              }
            }
          }
        }

        // check if template is selected and items are present in it
        const addTempItem = _.some(state.newCard.items, (item) => (
          !_.isEmpty(item.dataFromTemplate) && item.dataFromTemplate.items
          && item.dataFromTemplate.items.length > 0));

        // if planning -> cord and template is selected (single move)
        if ((state.selectedOrder.stage === 'planning' || state.selectedOrder.stage === 'mixed')
        && ((state.selectedTemplate
        && !isPrefabMultiForward()
        && !_.isEmpty(state.selectedTemplate.items)) || addTempItem)) {
          // call template add dialog
          confirmAddTemplateItems();
        } else if ((state.selectedOrder.stage === 'planning' || state.selectedOrder.stage === 'mixed')
          && isPrefabMultiForward() && state.selectedTemplate) {
          confirmAddTemplateItems();
        } else {
          // execute normally
          const qaLeadDate = _.some(state.newCard.items, (card) => (
            card.dataFromTemplate && card.dataFromTemplate.leadDates.qa !== null
          ));
          if (state.newCard.purpose === 'kit' && state.newCard.createdVia === 'p6'
            && qaLeadDate && _.some(state.newCard.simpleDates, ['isDirty', false])) {
            state.showModal = true;
          } else {
            prefabDatefill(true);
            try {
              await prepareToMove();
            } catch (err) {
              throw err;
            }
          }
        }
      } catch (e) {
        const errorMsg = `${e}`;
        toast.error(errorMsg);
      } finally {
        state.isLoading = false;
      }
    }, 1000, { leading: true, trailing: false });

    // validations for required field
    // eslint-disable-next-line vue/return-in-computed-property
    const isDisabled = computed(() => {
      if (
        type === 'material-edit-preparation'
      ) {
        if (isMulti) {
          // multi move requesting -> sourcing
          const prodLoc = state.newCard.location;
          return !prodLoc || !state.selectedRow.length;
        }
        if (
          (state.selectedRow.length === 0
          || state.newCard.oldName.length < 3)
        ) {
          return true;
        }
        return false;
      }
      if (type === 'material-edit-sourcing') {
        if (isMulti) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          state.requiredDates = [];
          const d = state.selectedOrders;
          for (let i = 0; i < d.length; i++) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            state.requiredDates.push(d[i].simpleDates.shipBy?.value);
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            state.requiredDates.push(d[i].simpleDates.deliver?.value);
          }
          const nameLen = _.some(d, (order) => order.name.length < 3);
          const isMissingDates = hasEmptyValues(state.requiredDates);
          return !!isMissingDates || nameLen;
        }

        const shipByDate = state.selectedOrder.simpleDates.shipBy?.value
          ? state.selectedOrder.simpleDates.shipBy?.value
          : '';
        const deliverDate = state.selectedOrder.simpleDates.deliver?.value
          ? state.selectedOrder.simpleDates.deliver?.value
          : '';
        return !shipByDate.length > 0 || !deliverDate.length > 0
        || state.selectedOrder.name.length < 3;
      }
      if (type === 'prefab-edit') {
        const coordDate = state.newCard.simpleDates.coord?.value ? state.newCard.simpleDates.coord?.value : '';
        const deliverDate = state.newCard.simpleDates.deliver?.value ? state.newCard.simpleDates.deliver?.value : '';
        if (
          (state.selectedRow.length === 0
          || state.newCard.name.length < 3 || (!state.isOneOrderPerItem
          && !isMulti)) && (_.isEmpty(coordDate) || _.isEmpty(deliverDate))
        ) {
          return true;
        }
        return false;
      }
      if (type === 'po-edit') {
        if (isMulti) {
          // multi move coord -> detailing
          const orders = state.selectedOrders;
          const ordersWithNoDetailByDates = orders.filter((order) => order.simpleDates.poDetailBy && order.simpleDates.poDetailBy.value === '');
          const prodLoc = state.newCard.location;
          return !_.isEmpty(ordersWithNoDetailByDates) || !prodLoc;
        }
        const poDetailByDate = state.newCard.simpleDates.poDetailBy?.value
          ? state.newCard.simpleDates.poDetailBy?.value
          : '';
        const deliverDate = state.newCard.simpleDates.deliver?.value
          ? state.newCard.simpleDates.deliver?.value
          : '';
        const prodLoc = state.newCard.location;
        return !poDetailByDate.length > 0
          || !deliverDate.length > 0
          || !prodLoc;
      }

      if (type === 'manager-edit-detailing' && kind === 'forward') {
        if (isMulti) {
          // multi move detailing -> manufacturing
          const orders = state.selectedOrders;
          const ordersWithNoManufDate = orders.filter((order) => order.manager.simpleDates.manufactureBy && order.manager.simpleDates.manufactureBy.value === '');
          return !_.isEmpty(ordersWithNoManufDate);
        }

        const poManufactureByDate = state.newCard.manager.simpleDates.manufactureBy?.value
          ? state.newCard.manager.simpleDates.manufactureBy?.value
          : '';
        return !poManufactureByDate.length > 0;
      }
      if (type === 'manager-edit-manufacturing' && kind === 'forward') {
        if (isMulti) {
          // multi move manufacturing -> qa
          const orders = state.selectedOrders;
          const ordersWithNoQaDate = orders.filter((order) => order.manager.simpleDates.qaBy && order.manager.simpleDates.qaBy.value === '');
          return !_.isEmpty(ordersWithNoQaDate);
        }
        const deliverDate = state.newCard.manager.simpleDates.qaBy?.value
          ? state.newCard.manager.simpleDates.qaBy?.value
          : '';
        return !deliverDate.length > 0;
      }
    });
    const disableBomCheckbox = computed(() => {
      const { items } = state.newCard;
      return !_.some(items, (item) => !_.isEmpty(item.catId));
    });

    const columnFields = (card, column) => {
      card.isOneOrderPerItem = state.isOneOrderPerItem;
      if (_.isFunction(column.fields) && isMulti && type === 'manager-edit-detailing') {
        return column.fields(state.selectedOrders);
      }
      if (_.isFunction(column.fields)) return column.fields(card);
      return column.fields;
    };

    const templateBtnLabel = computed(() => {
      if (state.isOneOrderPerItem) {
        return 'Apply Template To Selected';
      }
      return 'Select Template';
    });

    if (type === 'prefab-edit' && isMulti) {
      watch(() => state.selectedOrders,
        async () => {
          const selectedTemps = [];

          _.forEach(state.selectedOrders, (order) => {
            if (order.dataFromTemplate) {
              selectedTemps.push(_.cloneDeep(order.dataFromTemplate));
            }
          });
          if (selectedTemps.length > 0) {
            const uniqueValues = new Set(selectedTemps.map((t) => t._id));
            if (uniqueValues.size < selectedTemps.length) {
              // if duplicate selected templates
              [state.selectedTemplate] = selectedTemps;
            } else {
              state.selectedTemplate = {
                name: '(Varies)',
                templateName: '(Varies)',
              };
            }
          }
        }, { deep: true });
    }
    onMounted(async () => {
      if (store.state.activeScreen === 'schedule') {
        state.dataFetched = true;
        return;
      }
      state.isLoading = true;
      state.templateProject = (await Projects.haveTemplateProject()).templateProject;
      if (!_.isEmpty(state.templateProject)) {
        state.defaultTemplate = await ProductionTemplates.getDefaultTemplate({
          projectId: state.templateProject._id,
          purpose: state.selectedOrder.purpose,
        });
        if ((constants['po-edit-forward'].nextStages[0] === 'coordination' && state.selectedOrder.__t === 'Prefabs')
        || isPoSingleForward.value) {
          state.prodTemplates = (await SupplyChain.supplyChain({
            projectId: state.templateProject._id,
            module: 'ProdTemplates',
            filterNoItemOrders: false,
            limit: 9999,
            page: 1,
          })).data;
        }
      }
      prefabDatefill();
      state.dataFetched = true;
    });
    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      state.selectedItem = payload.data;
      state.isItem = payload.isItem;
      state.isSlideNotesActive = payload.isActive;
      state.fromMove = payload.fromMove;
      state.fromCreatePurchase = payload.isPurchaseModal;
      state.rowData = state.selectedOrders.filter((obj) => obj.id === payload.orderId);
    });

    const updateNotes = async (event) => {
      if (event.type === 'save') {
        const data = _.cloneDeep(event.data);
        for (let i = 0; i < state.selectedOrders.length; i++) {
          if (data.id === state.selectedOrders[i].id) {
            state.selectedOrders[i].currentNote = data.currentNote;
            state.selectedOrders[i].notes = data.notes;
          }
        }
      }
    };

    const setItemTemplate = (param) => {
      if (param.all) {
        // eslint-disable-next-line array-callback-return
        state.newCard.items.map((i) => { i.dataFromTemplate = param.template; });
      } else {
        for (const item of state.newCard.items) {
          if (item._id.toString() === param.itemId) {
            item.dataFromTemplate = param.template;
            break;
          }
        }
      }
      /* for combined asembly card if template of one assembly is changed,
      template of other assemblies should also be changed
      since the is only one assembly card to which single template has to be applied */
      if (!state.isOneOrderPerItem) {
        const item = _.find(state.newCard.items, { _id: param.itemId });
        if (item && item.purpose === 'assembly') {
          for (let idx = 0; idx < cards[0].items?.length; idx++) {
            const itm = cards[0].items[idx];
            if (itm.purpose === 'assembly' && itm._id !== item._id) {
              _.set(itm, 'dataFromTemplate', param.template);
            }
          }
        }
      }
    };
    const closeNotesSlider = (() => {
      state.isSlideNotesActive = false;
      moveTable.value.refreshTable();
    });

    const hasNestedInvLoc = function () {
      let cardProject = state.selectedOrders[0].project;
      cardProject = store.getters.findProject({ _id: cardProject._id });
      return cardProject.hasNesetedProjectInvLoc(store.state.userData);
    };

    const showDeliverMaterialDate = computed(() => {
      const showDate = _.some(state.selectedOrders, (card) => card._createTR
        || (card.materials ? card.materials.length : false)) && hasNestedInvLoc();
      return showDate && state.selectedOrders.length > 1;
    });

    const setDeliverMaterialDate = (dateObj) => {
      if (_.some(state.selectedOrders, (cardForTr) => cardForTr._createTR)) {
        state.newCard.addOrUpdateDate(dateObj.kind.value, moment(dateObj.val).toISOString());
      }
    };
    const setBackModal = computed(() => {
      if (kind === 'backward') return 'is-visible';
      if (type !== 'material-edit-sourcing') return 'px-0 pb-0';
      return '';
    });

    const renderDynamicCols = (col) => {
      if (col.key === 'itemsCount' && !isCutOrder) {
        return false;
      }
      // if (col.key === 'bomReserve' && ( !isCutOrder &&  (state.newCard._bomCreated || !state.newCard.materials.length))) {
      //   return false;
      // }
      return true;
    }
    return {
      ...toRefs(state),
      getSelectedRow,
      moveTable,
      getSearchValue,
      loadData,
      cancel,
      saveAndMoveNext,
      isDisabled,
      columnFields,
      toggleBomCheckbox,
      templateBtnLabel,
      getTemplateName,
      onePerItemChanged,
      loadTemplates,
      fillTemplateData,
      addTemplateItems,
      updateOrderTemplates,
      openApplyTempToMultiItemsModal,
      disableBomCheckbox,
      updateNotes,
      closeNotesSlider,
      showDeliverMaterialDate,
      setDeliverMaterialDate,
      setBackModal,
      itemTableBackWard,
      setItemTemplate,
      renderDynamicCols,
    };
  },
};
</script>

<style scoped>
.move-order-table ::v-deep(.o-table tbody) {
  min-height: 100% !important;
  max-height: 40vh !important;
}
.sourcing-move ::v-deep(.o-table tbody) {
  min-height: 40vh !important;
}
/* Please take care when implementing media query */
.material-move-table ::v-deep(.o-table){
  width: 1920px !important;
}
::v-deep(.modal-date .is-pulled-right) {
  float: unset !important; /* on-site date left aligned*/
}
</style>
