import {
  computed, nextTick, reactive, toRefs,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import _ from 'lodash';
import Catalogs from '@/models/Catalogs';
import Validation from '@/components/utils/Validations';
import moment from 'moment';
import { useToast } from 'vue-toastification';
import SupplyChain from '@/models/SupplyChain';
import QtyOptions from '@/components/utils/QtyOptions';
import { BaseOrder } from '@/models/BaseOrder';
import Helper from '@/models/Helper.js';

export default function () {
  const store = useStore();
  const route = useRoute();
  const toast = useToast();
  const data = reactive({
    existingCatIds: [],
    catIdsFetched: false,
    itemLimit: 100,
    importFromPrefab: 'excel',
    allLocations: [],
    dates: [],
    batchNo: 0,
  });

  const companyData = computed(() => store.state.companyData);

  const usedCatIds = (card) => _.compact(_.map(card.items, 'catId'));
  const filterItems = (card) => _.filter(card.items, (i) => !i.archived.value);

  const filteredRuns = (card) => _.filter(card.manager.runs, (r) => !r.archived.value);

  const itemLimit = (card) => {
    let key = 'prefab';
    if (card.isMM()) key = 'mm';
    if (card.isPM()) key = 'pm';
    if (card.isPO() || card.isProductionTemplate()) key = 'po';

    return store.state.itemLimits[key];
  };

  const isCatIdRequired = (card) => {
    const projectSetting = _.find(card.project.projectSettings, {
      companyId: card.owner.company._id,
    });
    return projectSetting ? projectSetting.isCatIdRequired : false;
  };

  const newOrderingCard = (card) => card.stage === 'ordering' && route.params.cardId === 'add';

  // eslint-disable-next-line max-len
  const isValidInteger = (item, field, max = 9999) => !(
    Number.isNaN(parseInt(item[field], 10))
    || item[field] > max
    || item[field] < 0
  );

  const validateDates = (obj, dates) => {
    // set date value to be sent in prop, sequence will get validated in excel import comp.
    if (dates) data.dates = dates;
    for (const date of data.dates) {
      date.val = obj[date.kind];
    }
  };

  // eslint-disable-next-line max-len
  const isValidFloat = (item, field, max = 9999) => !(
    Number.isNaN(parseFloat(item[field]))
    || item[field] > max
    || item[field] < 0.01
  );

  const isLocValid = (run, allLocations) => {
    const allLocs = [];
    _.forEach(allLocations, (loc) => {
      allLocs.push(...loc.val);
    });
    const loc = _.find(allLocs, (location) => _.startCase(location.name) === _.startCase(run.location));
    if (loc) return true;
    return false;
  };

  const sourceAccessor = (forShipping) => (forShipping ? 'order' : 'prefab');

  const isSourceShipment = (card) => card.delivery && !_.isEmpty(card.delivery);

  const getOrdersList = async (card) => {
    const queryParams = {
      projectId: card.project._id,
      limit: 1000,
      page: 1,
      excludeFields: {
        todos: 0,
        dates: 0,
        'items.serials': 0,
        'items.qtyLocations': 0,
      },
    };
    const module = 'Prefabs';
    queryParams.module = module === 'Sourcing' ? 'Materials' : module;
    queryParams.stage = module === 'Sourcing' ? 'delivery' : '';
    const orders = await SupplyChain.supplyChain(queryParams);
    const orderList = _.filter(orders.data, (el) => {
      if (el.multiTrade.value) { return card.multiTrade.value === el.multiTrade.value; }
      if (module === 'Sourcing' || el.purpose === 'kit') {
        return false;
      }
      return true;
    });

    if (!isSourceShipment(card) && card.isPM()) {
      queryParams.module = 'ProductionOrder';
      queryParams.stage = 'coordination';
      const pos = await SupplyChain.supplyChain(queryParams);
      pos.data.forEach((order) => {
        if (order.multiTrade.value && card.multiTrade.value === order.multiTrade.value) {
          orderList.push(order);
        }
      });
    }

    return orderList;
  };

  const getOrder = async (card, params) => {
    const orderList = await getOrdersList(card);

    return _.find(
      orderList,
      { _id: _.get(card, `items[0].${sourceAccessor(params.forShipping)}._id`, '') },
    ) || orderList[0];
  };

  const validateRun = (obj, allLocations) => {
    let errMsg = '';
    if (obj.hours && obj.hours !== '' && !_.isInteger(obj.hours)) {
      errMsg = 'Run hours should be a whole number';
    } else if (obj.minutes && obj.minutes !== '' && !_.isInteger(obj.minutes)) {
      errMsg = 'Run minutes should be a whole number';
    } else if (obj.seconds && obj.seconds !== '' && !_.isInteger(obj.seconds)) {
      errMsg = 'Run seconds should be a whole number';
    } else if (!obj.name || (obj.name && obj.name.length < 3)) {
      errMsg = 'Run name is mandatory and should be atleast 3 characters long';
    } else if (obj.owner && !Validation.validateEmail(obj.owner)) {
      errMsg = 'Owner Field must have a valid email!';
    } else if (obj.location && !isLocValid(obj, allLocations)) {
      errMsg = 'Location not recognised';
    } else if (obj.startDate > obj.finishDate) {
      errMsg = 'Run Start Date should not be greater than Run Finish Date.';
    } else if (obj.hours || obj.minutes || obj.seconds) {
      let temp = {};
      temp.hours = Helper.getTotalSeconds(obj, temp);
      temp.hours = Math.floor(temp.hours / 3600);
      if (!isValidInteger(temp, 'hours', 999)) errMsg = 'Hours must be an integer in the range 0 - 999';
    }
    if (!errMsg && data.dates) {
      validateDates(obj);
    }
    return errMsg;
  };

  const validateItem = (item) => {
    let errMsg = null;
    if (!item._usedCatIds) item._usedCatIds = [];
    if (
      !['material-edit-ordering'].includes(
        store.state.activeScreen,
      )
      && (_.isEmpty(item.name.toString().trim()) || (item.name && item.name.toString().trim().length < 3))
    ) {
      errMsg = 'Name should be atleast 3 characters long';
    } else if (item.quantity && !isValidInteger(item, 'quantity')) {
      errMsg = 'Quantity should be an integer in the range 0 - 9999';
    } else if (!_.isEmpty(item.coordDate) && !_.isEmpty(item.deliverDate)) {
      if (new Date(item.coordDate) > new Date(item.deliverDate)) {
        errMsg = 'Coordination date should be before Delivery date!';
      }
    } else if (!_.isEmpty(item.catId)) {
      item.catId = Validation.getValidCatId(item.catId);
      if (!Validation.validateCatalogId(item.catId)) {
        errMsg = 'Catalog ID should be 4 to 32 character long';
      } else if (item._usedCatIds.includes(item.catId)) {
        errMsg = 'Catalog ID has already been used by existing item';
      }
    }
    if (!errMsg && item.measure) {
      if (!isValidFloat(item, 'measure')) {
        errMsg = 'Measure should be an integer in the range 0.01 - 9999';
      } else if (!item.measureUnits) {
        errMsg = 'If measure field has value, then measure units field becomes mandatory';
      }
    }
    if (item.measureUnits) {
      if (!item.measure) {
        errMsg = 'If measure units field has value, then measure field becomes mandatory';
      } else if (!Validation.measureUnitsCheck(item, companyData.value)) {
        errMsg = 'Measure units must be a part of company measure units';
      }
    }
    if (!errMsg && data.dates) {
      validateDates(item);
    }
    return errMsg;
  };

  const addFiles = (item, newItem) => {
    let docNames = [];
    let documents = [];
    let docNm = '';
    if (_.isUndefined(newItem)) {
      // for prefabs and mm card import
      newItem = item;
    }
    const rgex = new RegExp('^(http|https)://', 'i');
    if (!_.isEmpty(item.documents)) {
      if (
        !_.isUndefined(item.documents)
        && (item.documents.indexOf(',') > -1 || item.documents.indexOf(' ') > -1)
      ) {
        documents = item.documents.split(',');
        if (documents.length <= 1) {
          documents = item.documents.split(' ');
        }
        if (!_.isUndefined(item.docNames)) {
          docNames = item.docNames.split(',');
        }
        if (documents.length > 0) {
          for (let doc = 0; doc < documents.length; doc++) {
            if (!_.isEmpty(docNames[doc])) {
              const file = newItem.newFile();
              if (!_.isEmpty(docNames[doc])) {
                docNm = _.trim(docNames[doc]);
              } else {
                docNm = (documents[doc]);
                docNm = _.trim(documents[doc]);
                if (!docNm.match(rgex) && docNm) {
                  docNm = `http://${docNm}`;
                }
              }
              const matchRes = _.trim(documents[doc]).match(rgex);
              if (documents[doc] && !documents[doc].match(rgex)) {
                if (!_.isEmpty(matchRes) && matchRes?.includes('http') || matchRes?.includes('https')) {
                  documents[doc] = `${_.trim(documents[doc])}`;
                }
                else documents[doc] = `http://${_.trim(documents[doc])}`;
              }
              _.assign(file, {
                url: documents[doc],
                name: docNm,
                type: 'url',
              });
            }
          }
        }
      } else {
        if (['kit', 'assembly'].includes(item.purpose) && item._id) {
          if (item.stage !== 'planning') return item;
          const idx = _.findIndex(
            item.files,
            (file) => file.name === item.docNames,
          );
          if (idx > -1 && item.files[idx].url === item.documents) return item;
        }
        const file = newItem.newFile();
        if (!item.documents.match(rgex)) {
          item.documents = `http://${_.trim(item.documents)}`;
        }
        item.docNames = _.trim(item.docNames);
        // setting doc url as name if name is not defined
        if (!item.docNames.length) item.docNames = item.documents;
        _.assign(file, {
          url: item.documents,
          name: item.docNames,
          type: 'url',
        });
      }
    }
    return newItem;
  };

  const validateExcelItem = async (card, item, obj, functionDefinition, params, kit, currBatchNo) => {
    if (data.batchNo !== currBatchNo) {
      data.catIdsFetched = false;
      data.batchNo = currBatchNo;
    }
    data.dates = _.get(params, 'dates', []);
    let errMsg = '';
    if (functionDefinition) {
      errMsg = await functionDefinition(item, obj, kit);
      return errMsg;
    }
    item._usedCatIds = usedCatIds(card);
    if (item.catId) item.catId = Validation.getValidCatId(item.catId);
    if (!data.catIdsFetched) {
      // fetch just once
      let excelCatIds = _.compact(_.map(obj, 'catId'));
      // eslint-disable-next-line no-nested-ternary
      excelCatIds = excelCatIds.map((catId) => (catId ? (!_.isString(catId) ? Validation.getValidCatId(catId.toString().toUpperCase()) : Validation.getValidCatId(catId.toUpperCase())) : null));
      
      const catalogParams = {
        catId: _.compact(excelCatIds),
        type: 'UpcItems',
        limit: 9999,
        page: 1,
      }
      if(card.isCutOrder) {
        catalogParams.type = 'Assembly';
        catalogParams.isCutPartList = true;
      }
      const existingCatalogs = await Catalogs.getAssemblyParts(catalogParams);
      data.existingCatIds = _.map(existingCatalogs.data, 'catId');
      data.catIdsFetched = true;
    }
    if (
      (
        (isCatIdRequired(card) || newOrderingCard(card))
        && _.isEmpty(item.catId)
      )
      || (card.isCutOrder && _.isEmpty(item.catId))
    ) {
      errMsg = 'Missing Catalog ID';
    }

    if (item.catId && !data.existingCatIds.includes(item.catId)) {
      errMsg = 'Catalog ID does not exist';
    }
    if (!errMsg) {
      errMsg = validateItem(item);
    }
    // eslint-disable-next-line consistent-return
    return errMsg;
  };

  const saveExcelIteminMM = async (card, obj) => {
    toast.warning('Item Name and Id will be in sync with Master Catalog!');
    const catIds = _.compact(_.map(obj, 'catId'));
    const parts = (
      await Catalogs.getAssemblyParts({
        catId: _.uniq(catIds),
        type: 'UpcItems',
      })
    ).data;
    for (const item of obj) {
      if (_.has(item, '_isValid') && !item._isValid) continue;
      if (filterItems.length >= itemLimit) {
        toast.warning(`Only ${data.itemLimit} items can be added`);
        break;
      }
      const temp = _.pick(item, [
        'customId',
        'name',
        'catId',
        'activityId',
        'isRequired',
        'measure',
        'documents',
        'docNames',
        'measureUnits',
        'unitCost',
        'leadTime',
        'purchase',
        'excelFile',
      ]);
      temp.quantity = item?.quantity || 1;
      if (!_.isEmpty(temp.catId)) {
        const existingCatalog = _.find(
          parts,
          (part) => part.catId === temp.catId,
        );
        if (!_.isEmpty(existingCatalog)) {
          temp.name = existingCatalog.name;
          temp.customId = existingCatalog.customId;
        }
      }
      const itemIdx = card.addItem({
        isEditing: true,
        stage: card.stage === 'mixed' && card._customStage === 'preparation' ? card._customStage : card.stage,
        purchase: _.has(temp, 'purchase') ? item.purchase : store.state.activeScreen === 'material-edit-preparation',
        ...temp,
      });
      let newItem = card.items[itemIdx];
      const memo = newItem.newFTD('memos');
      if (!_.isEmpty(item.note)) {
        memo.text = item.note;
      }
      newItem.quantity = _.clamp(newItem.quantity, 0.01, 9999);
      const qaRequired = ['yes', 'true', '1'];
      if (
        !_.isEmpty(newItem.isRequired)
        && qaRequired.includes(newItem.isRequired.toLowerCase())
      ) {
        _.set(newItem.qa, 'isRequired', true);
      } else {
        _.set(newItem.qa, 'isRequired', false);
      }
      if (newItem.measureUnits) _.set(newItem, 'measureUnits', newItem.measureUnits.toLowerCase());
      newItem = addFiles(item, newItem);
      const dates = ['orderBy', 'available', 'shipBy', 'deliver'];
      dates.forEach((d) => {
        if (!_.isUndefined(item[d])) {
          newItem.newDate(d);
          newItem.simpleDates[d].value = moment(item[d]).toJSON();
        }
      });
      if (!_.isEmpty(item.verifyBy)) {
        newItem.qa.dueBy = moment(item.verifyBy).isValid()
          ? moment(item.verifyBy)
          : '';
      }
      if (!_.isEmpty(newItem.leadTime)) {
        const valueToAssign = newItem.leadTime > 0 ? Math.floor(newItem.leadTime) : 0;
        newItem.leadTime = valueToAssign;
      }
      if (!_.isEmpty(newItem.unitCost) && newItem.unitCost > 0) {
        newItem.calcUnitCost = newItem.unitCost;
      }
      if (temp.excelFile) _.set(newItem, 'excelFile', temp.excelFile);
    }
  };

  const saveExcelIteminPrefab = (card, obj) => {
    for (const item of obj) {
      if (_.has(item, '_isValid') && !item._isValid) continue;
      if (filterItems(card).length >= itemLimit(card)) {
        toast.warning(`Only ${itemLimit(card)} items can be added in this stage`);
        break;
      }
      const temp = _.pick(item, [
        'customId', 'name', 'quantity', 'level', 'zone', 'costCode',
        'measure', 'documents', 'measureUnits', 'excelFile',
      ]);
      const itemIdx = card.addItem({ isEditing: true, ...temp });
      let newItem = card.items[itemIdx];
      if (!_.isEmpty(item.note)) {
        const memo = newItem.newFTD('memos');
        memo.text = item.note;
      }
      if (newItem.measureUnits) _.set(newItem, 'measureUnits', newItem.measureUnits.toLowerCase());
      newItem = addFiles(item, newItem);
      if (!_.isEmpty(item.coordDate) || !_.isEmpty(item.coordDate?.toJSON())) {
        newItem.newDate('coord');
        newItem.simpleDates.coord.value = moment(item.coordDate).toJSON();
      }
      if (!_.isEmpty(item.deliverDate) || !_.isEmpty(item.deliverDate?.toJSON())) {
        newItem.newDate('deliver');
        newItem.simpleDates.deliver.value = moment(item.deliverDate).toJSON();
      }
      newItem.quantity = _.clamp(
        Math.floor(newItem.quantity),
        1,
        QtyOptions.inputProps.max,
      );
    }
  };

  const saveExcelIteminPO = async (card, items, params) => {
    const order = await getOrder(card, params);
    items.forEach((item) => {
      Object.assign(item, {
        cards: [order._id],
        [sourceAccessor(params.forShipping)]: { _id: order._id, name: order.name },
        order: { _id: order._id },
        forceEditing: true,
        status: 'NotUsed',
        stage: card._customStage,
      });
    });
    // this.$emit('close', items, this.orderList);
    const prefabList = await getOrdersList(card);
    if (items && prefabList && prefabList.length) {
      if (data.importFromPrefab === 'excel' && items) {
        for (const item of items) {
          if (filterItems(card).length >= itemLimit(card)) {
            toast.warning(`Only ${itemLimit(card)} items can be added in this stage`);
            break;
          }
          const temp = _.pick(item, [
            'customId',
            'name',
            'quantity',
            'level',
            'zone',
            'costCode',
            'measure',
            'documents',
            'prefab',
            'excelFile',
          ]);
          if (item.measureUnits) temp.measureUnits = Validation.measureUnitsCheck(item, store.state.companyData);
          const itemIdx = card.addItem({
            isEditing: true,
            forceEditing: true,
            stage: 'planning',
            ...temp,
          });
          let newItem = card.items[itemIdx];
          if (!_.isEmpty(item.note)) {
            const memo = newItem.newFTD('memos');
            memo.text = item.note;
          }
          newItem.quantity = _.clamp(
            Math.floor(newItem.quantity),
            1,
            QtyOptions.inputProps.max,
          );

          if (temp.excelFile) _.set(newItem, 'excelFile', temp.excelFile);
          if (newItem.measure) newItem.measure = _.clamp(newItem.measure, 0, 9999);
          newItem = addFiles(item, newItem);
        }
      } else if (_.isArray(items)) {
        items.forEach((item) => {
          const index = card.addItem(item);
          card.items[index].newFTD('memos');
        });
      }
    }
    data.importFromPrefab = 'none';
  };

  const saveExcelIteminPM = async (card, obj, params) => {
    obj.forEach((item) => {
      Object.assign(item, {
        cards: [_.get(params, 'selectedPrefab._id', '')],
        [sourceAccessor(params.forShipping)]: { _id: _.get(params, 'selectedPrefab._id', ''), name: _.get(params, 'selectedPrefab.name', '') },
        order: { _id: _.get(params, 'selectedPrefab._id', '') },
        forceEditing: true,
        status: 'NotUsed',
        stage: card.leastStage || card._customStage || card.stage,
        _customStage: card._customStage || card.stage,
        __t: card.__t,
      });
      if (card.isCutOrder ) item.cards = [...item.cards, card._id]
      if (item.fromExcel && card.importExcel) {
        Object.assign(item, { excelFile: card.importExcel });
      }
    });
    _.map(obj, (item) => {
      if (item.measureUnits) item.measureUnits = Validation.measureUnitsCheck(item, store.state.companyData);
      const itemIdx = card.addItem({ ...item });
      let newItem = card.items[itemIdx];
      newItem = addFiles(item, newItem);
      newItem.quantity = _.clamp(Math.floor(newItem.quantity), 1, QtyOptions.inputProps.max);
      const runs = _.filter(
        filteredRuns(card),
        (r) => r.runItemsCount.length < BaseOrder.runItemLimit,
      );
      if (runs && runs.length > 0) {
        for (const run of runs) {
          run.runItemsCount.push(card.manager.getDefaultRi({
            item: newItem,
            run,
          }));
        }
      }
      card.manager.runItemsIndexMap();
      if (card.manager.pTrackEnabled && card._customStage !== 'manufacturing') {
        newItem.markCompleteDisabled = true;
      }
      if (!_.isEmpty(item.note)) {
        const memo = newItem.newFTD('memos');
        memo.text = item.note;
      }
      if (newItem.measure) newItem.measure = _.clamp(newItem.measure, 0, 9999);
      if (newItem.measureUnits) _.set(newItem, 'measureUnits', newItem.measureUnits.toLowerCase());

      if (!_.isEmpty(item.coordDate)) {
        newItem.newDate('coord');
        newItem.simpleDates.coord.value = moment(item.coordDate);
      }
      if (!_.isEmpty(item.deliverDate)) {
        newItem.newDate('deliver');
        newItem.simpleDates.deliver.value = moment(item.deliverDate);
      }
    });
  };

  const saveExcelItem = async (card, items, params, functionDefinition,
    excelFileName, isFinalBatch, currBatchNo, kit, allProjectLocations) => {
    if (functionDefinition) {
      await functionDefinition(items, allProjectLocations, kit);
    } else {
      const key = card.isManager === true ? 'ProductionManager' : card.__t;
      const mappedSave = {
        Materials: saveExcelIteminMM,
        Sourcing: saveExcelIteminMM,
        Prefabs: saveExcelIteminPrefab,
        ProductionManager: saveExcelIteminPM,
        ProductionOrder: saveExcelIteminPO,
      };
      await mappedSave[key](card, items, params);
    }
  };

  return {
    ...toRefs(data),
    companyData,
    isValidInteger,
    validateDates,
    isValidFloat,
    validateItem,
    addFiles,
    validateExcelItem,
    saveExcelItem,
    isCatIdRequired,
    newOrderingCard,
    saveExcelIteminMM,
    saveExcelIteminPrefab,
    sourceAccessor,
    getOrder,
    isSourceShipment,
    saveExcelIteminPO,
    getOrdersList,
    saveExcelIteminPM,
    validateRun,
  };
}
