<template>
  <div v-if="rowField.from === 'scheduleFlyout'">
    <button v-if="rowData._isEditing || rowData.isNew" @click="handleAction('reject')"
      :disabled="rowData.stage !== 'sourcing' && !rowData.isNew" class="button is-outlined is-danger">
      <i class="icon-removedelete"></i>
    </button>
  </div>
  <div
    v-else-if="
      (rowData?.isEditing && !rowField?.inputProps?.flyout) ||
        rowData?.detailRowEditing
    "
  >
    <button @click="handleAction('cancel')" v-tooltip="'Cancel'" class="button is-outlined is-danger">
      <i class="icon-close has-text-black-bis"></i>
    </button>
    <button
    v-if="!rowData?.isPartRow"
    @click="onCellClicked($event, 'save')" v-tooltip="'Save'"
    class="button is-success has-text-white">
      <i class="icon-checkmark has-text-white"></i>
    </button>
  </div>
  <div v-else-if="groupButtons" class="field is-grouped">
    <span v-for="(btn, idx) of groupButtons" :key="idx" class="control">
      <button
        :disabled="btn.disabled"
        :key="btn.event" v-tooltip="btn.tooltip"
        class="button has-text-black-bis"
        @click="onCellClicked($event, btn.event)">
          <span class="icon">
            <i :class="btn.icon"></i>
          </span>
      </button>
    </span>
  </div>
  <div v-else class="dot-button">
    <button
      :class="!showEdit ? 'visibility-hidden': ''"
      @click="handleAction('edit')"
      :disabled="disableActions('edit')"
      class="button" v-tooltip="'Edit'"
    >
      <span class="icon">
        <i class="icon-edit"></i>
      </span>
    </button>
    <button
      :class="!showDelete ? 'is-hidden': ''"
      @click="handleAction('delete')"
      class="button"
      :disabled="isDeleteDisabled"
    >
      <span class="icon">
        <i class="icon-removedelete"></i>
      </span>
    </button>
    <button
      v-if="rowField.inputProps.openRow"
      @click="onCellClicked($event, 'open')"
      class="button has-background-black-bis has-text-white"
       :href="getUrl()"
       @click.prevent=""
    >
      <span class="icon">
        <i class="fas fa-external-link-alt"></i>
      </span>
    </button>
    <button
      v-if="rowField.inputProps.cloneProjects"
      @click="onCellClicked($event, 'clone-poject')"
      class="button has-background-black-bis has-text-white"
    >
      <span class="icon">
        <i class="icon-clone"></i>
      </span>
    </button>
    <button
      v-if="rowField.inputProps.cloneProjects"
      @click="onCellClicked($event, 'clear-guids')"
      class="button has-background-black-bis has-text-white"
    >
      <span class="icon">
        <i class="fas fa-cloud"></i>
      </span>
    </button>
    <o-dropdown v-if="menu && menu.length > 0"
      aria-role="list"
      @active-change="activeChanged"
      class="is-action-dropdown" v-tooltip="disabledToolTip"
      :disabled="rowData?.isDisabled"
      >
      <template #trigger>
        <button class="button">
          <span class="icon">
            <i class="icon-more"></i>
          </span>
        </button>
      </template>
      <o-dropdown-item
        v-for="(opt, idx) in menu"
        :key="idx"
        @click="onCellClicked($event, opt.event)"
        :disabled="disableOptions(opt, rowData) || isOptionsDisabled"
        aria-role="listitem"
        :class="[{disabledPer: isDisabled3Dot(opt.event)}, (disableOptions(opt, rowData) &&
          ['schedule'].includes(activescreen)) ? 'is-hidden' : '']"
      >
        <span class="line-height">{{ opt.label }}</span>
      </o-dropdown-item>
      <o-dropdown-item class="control">
        <button class="button has-background-grey-lighter">
            <i class="icon-close"></i>
        </button>
      </o-dropdown-item>
    </o-dropdown>
  </div>
</template>
<script>
import {
  defineComponent, reactive, toRefs, computed, inject,
} from 'vue';
import {
  get, isFunction, set, cloneDeep, isUndefined, isEmpty,
} from 'lodash';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import screens from '@/constants';
import PermissionMixin from '@/components/mixins/PermissionMixin';
import RejectSourcing from '@/components/modals/RejectSourcing.vue';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';

export default defineComponent({
  name: 'actions-field',
  props: {
    isHeader: Boolean,
    rowData: Object,
    rowField: Object,
    rowIndex: Number,
    actions: Array,
  },
  setup(props, { emit }) {
    const {
      rowData,
      rowField,
    } = props;
    const state = reactive({
      disabledToolTip: '',
    });
    const { getPermissionValues } = PermissionMixin();
    const emitter = inject('emitter');
    const store = useStore();
    const route = useRoute();
    const user = store.state.userData;
    const router = useRouter();
    const screen = computed(() => store.state.activeScreen);
    const menu = computed(() => {
      // if (props.actions) {
      //   return actions;
      // }
      // eslint-disable-next-line
      const actions = get(props.rowField, 'inputProps.options', false);
      if (props.rowField?.inputProps && isFunction(props.rowField?.inputProps.options)) {
        return actions(props.rowData, store, user, route.params.stage);
      }
      return actions;
    });
    const showEdit = computed(() => {
      if (rowField.inputProps
      && isFunction(rowField.inputProps.showEdit)) {
        return rowField.inputProps.showEdit(rowData, user, store);
      }
      return (get(rowField, 'inputProps.showEdit', true));
    });
    const showDelete = computed(() => {
      if (rowField.inputProps
      && isFunction(rowField.inputProps.showDelete)) {
        if (store.state.activeScreen === 'cutParts') return rowField.inputProps.showDelete(rowData, store.state.activeScreen);
        return rowField.inputProps.showDelete(rowData);
      }
      return (get(rowField, 'inputProps.showDelete', false));
    });
    const isDeleteDisabled = computed(() => {
      if (!isUndefined(get(rowField, 'inputProps.disableDelete')) && isFunction(get(rowField, 'inputProps.disableDelete'))) {
        return rowField.inputProps.disableDelete(rowData);
      }
      return get(rowField, 'inputProps.disableDelete', true);
    });
    const emitRefresh = () => {
      emitter.emit('refresh-inv-table');
    }

    const emitLoading = (showLoading) => {
      emitter.emit('show-inv-loading', showLoading);
    }
    const handleAction = (action) => {
      if (action === 'edit') {
        emit('cell-clicked', { type: action });
        set(rowData, 'isEditing', !rowData?.isEditing);
        set(rowData, '_beforeEdit', cloneDeep(rowData));
      } else if (action === 'cancel') {
        Object.assign(rowData, rowData._beforeEdit);
        set(rowData, 'isEditing', !rowData.isEditing);
        emit('cell-clicked', { type: action, data: rowData });
      } else if (action === 'delete') {
        if (!isUndefined(get(rowField, 'inputProps.archiveItems')) && isFunction(get(rowField, 'inputProps.archiveItems'))) {
          rowField.inputProps.archiveItems(store.getters.selectedValuesForKey, rowData, emitRefresh, emitLoading);
        }
      } else if (action === 'reject') {
        if (rowData.isNew) {
          emit('cell-clicked', { type: 'refresh-details' });
        } else {
          ModalProgrammatic.open({
            component: RejectSourcing,
            props: {
              items: [{ _id: get(rowData, '_id', '') }],
              orderId: get(rowData, 'cards.0', ''),
              itemReject: true,
            },
            canCancel: false,
            rootClass: 'modal-xs',
            events: {
              'refresh-card': async (opts) => {
                if (opts?.type === 'refresh-card') {
                  emit('cell-clicked', { type: 'refresh-table' });
                } else emit('cell-clicked', { type: 'refresh-details' });
              },
            },
          });
        }
      }
    };
    const getUrl = () => {
      if (store.state.activeScreen === 'inventory') {
        return false;
      }
      if (store.state.screen === 'projects') {
        const projId = rowData._id;
        return `#/projects/${projId}`;
      }
      const order = rowData.order || rowData;
      let orderKind = 'prefabs';
      if (!order.__t) return false;
      if (order.isPM()) orderKind = 'managers';
      if (order.isMM()) orderKind = 'material';
      if (order.isPO()) orderKind = 'orders';
      const projectId = order.project._id;
      const orderStage = order.isPO() || order.isPrefab()
        ? '' : order.getCardStage();
      const cardId = order.cardId || order._id;
      return `#/${orderKind}/${projectId}/${cardId}/${orderStage}`;
    };
    function onCellClicked(e, type) {
      emit('cell-clicked', { type });
    }
    const activeChanged = (active) => {
      emit('cell-clicked', { data: rowData, type: 'three-dot', active });
    };

    const isLoggedIn = computed(() => !(router.currentRoute.value.meta
      && router.currentRoute.value.meta.skipUser));

    const getPermKey = computed(() => {
      if (route.params.tab === 'GeneralInventory') return 'general_inventory';
      if (rowField.permissionKey === 'company_locations'
      && store.state.activeScreen === 'view-project') return 'projects';
      return rowField.permissionKey;
    });

    const permissionModule = () => {
      if (store.state.activeScreen === 'PMChecklist') return 'production-manager';
      if (rowField.permissionModule === 'checklist') {
        if (rowData.stage === 'mixed' && !isEmpty(rowData.todos.sources)) {
          return store.getters.getStageWiseModule(rowData.todos.sources[0]);
        }
        return store.getters.getStageWiseModule(rowData);
      }
      return rowField.permissionModule ? rowField.permissionModule : '';
    };

    const getProject = computed(() => {
      if (screen.value === 'projects') return { _id: rowData._id };
      if (screen.value === 'view-project') return { _id: route.params.projectId };
      return rowData.project;
    });

    const getRequiredCheckMap = computed(() => {
      const checkMap = {
        inventory: [{ module: 'material-manager', key: 'save' },
          { module: 'material-manager', key: 'move' }],
        transferReq: [{ module: 'inventory', key: 'save' },
          { module: 'inventory', key: 'quantity' }],
        checklist: [{ key: 'checklist' }],
        'material-status': [{ module: 'material-manager', key: 'save' }],
        'production-status': [{ module: 'production-manager', key: 'save' }],
      };
      return checkMap;
    });

    const getChecklistModule = computed(() => {
      const checklistModules = {
        Checklist: 'home',
        SCMChecklist: 'scm',
        PMChecklist: 'production-manager',
      };
      return checklistModules[screen.value];
    });

    const isDisabledOnPermission = (type) => {
      const permKey = getPermKey.value;
      const key = permKey || 'save';
      const pModule = permissionModule();
      if (screens[screen.value].isSubmittals) return true;
      if (pModule === 'shipping' && route.name === 'shipping' && rowData) {
        if (store.getters.hasShippingAccess(rowData)) {
          // if shipping has access then check for permission
          const res = store.getters.getPermissionVal({
            permissionModule: pModule, rowData,
          }, key);
          return !res;
        }
      }
      if (type === 'openCard' && rowField.permissionModule !== 'scm'
        && !(['shipping', 'sourcing-edit'].includes(route.name))) return false;
      if (pModule) {
        if (key === 'projects') rowData.project = getProject.value;
        let res = store.getters.getPermissionVal({
          permissionModule: pModule, rowData,
        }, type === 'openCard' && route.name !== 'shipping' ? 'data' : key);
        if (res || (res && rowField.permissionModule === 'checklist')) {
          let moduleType = pModule === 'inventory' ? 'inventory' : 'checklist';
          if (pModule === 'inventory' && key === 'transfer_request') moduleType = 'transferReq';
          if (pModule === 'scm') {
            moduleType = key;
          }
          getRequiredCheckMap.value[moduleType].forEach((pm) => {
            if (res) {
              res = store.getters.getPermissionVal({
                permissionModule: pm.module || getChecklistModule.value, rowData,
              }, pm.key);
            }
          });
        }
        return !res;
      }
      return false;
    };

    const disableActions = (type) => {
      const { meta } = router.currentRoute.value;
      const tabKey = get(meta, 'permissionKey', false);
      const tabPermissionKey = tabKey ? tabKey.split(':')[1] : '';
      if (isLoggedIn.value && store.getters.isViewer() && !store.getters.viewerHasPermission
        && !['company_profile', 'manufacton_settings'].includes(tabPermissionKey)) {
        return true;
      }
      if (type === 'edit') {
        return isDisabledOnPermission() || rowData?.isDisabled;
      }
      return false;
    };

    const getType = (type) => {
      if (['receive', 'cancelDelivery', 'remove'].includes(type) && route.name === 'shipping'
       && rowData) {
        if (store.getters.hasShippingAccess(rowData)) return '';
      }
      // need to check this when implementing app and projects
      // if (store.state.activeScreen === 'company-profile') {
      //   const tab = _.find(screens[store.state.activeScreen].headerTabs, {
      //     ref: this.$route.params.tab,
      //   });
      //   return tab.ref;
      // }
      if (type === 'openCard') return 'openCard';
      if (screen === 'projects') return `${type}App`;
      if (type === 'closeRequest') return 'saveAll';
      return get(screens[store.state.activeScreen], 'alterDataKey', type);
    };

    const getRequiredCheck = (type) => {
      const options = {
        orderMoreInv: [{ module: 'material-manager', key: 'data' }],
      };
      return options[type];
    };
    const disableOptions = (option, item) => {
      if (isFunction(option.isDisabled)) {
        return option.isDisabled(item);
      }
      return false;
    };

    const isDisabled3Dot = (type) => getPermissionValues({
      permissionModule: permissionModule(),
      type: getType(type),
      project: getProject.value,
      requiredCheckMap: getRequiredCheck(type),
    });

    const groupButtons = computed(() => {
      // check if the input props has groupButtons
      if (rowField.inputProps
      && isFunction(rowField.inputProps.groupButtons)) {
        return rowField.inputProps.groupButtons(rowData);
      }
      return false;
    });

    const isOptionsDisabled = computed(() => {
      if (props.rowField?.inputProps?.isOptionsDisabled) {
        const disableObject = isFunction(props.rowField?.inputProps?.isOptionsDisabled) ? props.rowField?.inputProps?.isOptionsDisabled(props.rowData) : { disabled: props.rowField?.inputProps?.isOptionsDisabled };
        state.disabledToolTip = disableObject.message ? disableObject.message : '';
        return disableObject?.disabled;
      }
      return false;
    });

    return {
      menu,
      ...toRefs(state),
      onCellClicked,
      handleAction,
      activeChanged,
      disableOptions,
      showEdit,
      showDelete,
      isDeleteDisabled,
      disableActions,
      getType,
      isDisabled3Dot,
      groupButtons,
      getUrl,
      activescreen: store.state.activeScreen,
      isOptionsDisabled,
    };
  },
});
</script>
<style scoped>
.dot-button {
  width: 80px;
}
</style>
