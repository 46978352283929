<template>
  <div class="pm-reorder-col">
    <mf-table
      ref="managerTable"
      :tableProps="tab.tableProps"
      :loadData="getLoadData()"
      :apiMode="getApiMode()"
      :purpose="getPurpose"
      :hideGutter="true"
      @cellClicked="onTableCellClicked"
      :key="refreshKey"
      :tab='tab'
      :isPmCardView="tab.isPmCardView"
      :isLocallyFiltered="true"
      :rowData="rowData"
    >
      <template v-slot:item-qty="{ rowData: item, rowField }">
        <qty-input
          type="number" min="0" :max="maxQty"
          v-model.number="item.quantity"
          :value="item.quantity"
          :isDisabled="disableInput(item) || (item?.order?.stage === 'manufacturing' && item?.order?.isCutOrder)"
          @update:value="updateAllQty(item)">
        </qty-input>
      </template>
      <template v-slot:owner-select="{ rowData: run }">
        <mf-multi-select
          v-model="run.owner.user"
          :options="allUsers"
          :closeOnSelect="true"
          label="name"
          track-by="_id"
          :multiple="false"
          :hideSelected="false"
          :allow-empty="false"
          :disabled="disableOwnerAndLoc.includes(run.order._customStage)"
          group-values="val" group-label="key"
          class="pendo-run-owner-select">
        </mf-multi-select>
      </template>
      <template v-slot:location-select="{ rowData: run }">
        <mf-multi-select
          v-model="run.location"
          :options="allLocations"
          :closeOnSelect="true"
          label="name"
          track-by="_id"
          :multiple="false"
          :hideSelected="false"
          :allow-empty="false"
          :disabled="disableOwnerAndLoc.includes(run.order._customStage)"
          group-values="val" group-label="key"
          class="pendo-run-location-select">
        </mf-multi-select>
      </template>
      <template v-slot:percDone="{ rowData }">
        <span v-if="rowData._customStage === 'manufacturing'">
          <input type="number" v-model.number="rowData.stats.percComplete"
            class="input timer-width-hour"
            :min="0"
            :max="100"
            :step="1"
            @change="onChange(rowData)"
            :disabled="disableProdTrack(rowData)"/>
          <o-checkbox class="is-success"
            @input="checkProductivity($event, rowData)"
            v-model="rowData.isCompleted"
            :disabled="rowData._customStage !== 'manufacturing'">
          </o-checkbox>
        </span>
        <span v-else>
          {{rowData.stats.percComplete}}
        </span>
      </template>
      <template v-slot:percCompleted="{ rowData }">
        <div class="is-flex is-justify-content-flex-end">
          <div v-if="isCheckboxDisabled(rowData,rowData.stats.percComplete,true)">
            {{ rowData.stats?.percComplete || 0 }}
          </div>
          <input type="number" v-else v-model.number="rowData.stats.percComplete"
            class="input"
            :min="0"
            :max="100"
            :step="1"
            :disabled="isCheckboxDisabled(rowData,undefined, true)"
            @input="{
              onChange(rowData,true);
            }"
          /> %
        </div>
      </template>

      <template v-slot:isDone="{ rowData }">
        <o-checkbox class="is-success"
          v-model="rowData.isCompleted"
          :disabled="isCheckboxDisabled(rowData)"
          @update:modelValue="onChangeisCompleted(rowData)"
          >
        </o-checkbox>
      </template>
      <template v-slot:item-check="{ rowData }">
        <o-checkbox class="is-success"
          :disabled="isItemCheckDisabled || isDisabledItemCheck(rowData)
            || isItemCheckDisabledOnQc(rowData) || disableInput(rowData) || isCheckboxDisabled(rowData)"
          v-model="rowData.isCompleted"
          v-tooltip="isItemCheckDisabledOnQc(rowData) ? 'Incomplete QA/QC.'
            : ''">
        </o-checkbox>
      </template>
      <template #qa-qc="props">
        <qaQcField
          :rowData="props.rowData"
          :rowField="props.rowField"
          :card="rowData"
          @addedForm="(form) => addedForm({ run: props.rowData, form})"
          @save="refreshCard"/>
      </template>
      <template #run-timer="props">
        <field-run-productivity
          :rowData="props.rowData"
          :rowField="props.rowField"
          :card="rowData"
          @cell-clicked="onTableCellClicked"
          :isHeader="props.isHeader"
        />
      </template>
    </mf-table>
    <excel-import
      :card="rowData"
      :tab="tab?.id"
      :isExcelModalActive="excelModal"
      :dates="dates"
      :batchSize="50"
      :validateExcelItem="validateExcelItem"
      :saveExcelItem="saveExcelItem"
      :selectedPrefab="selectedPrefab"
      :modalHeaderTitle="$_.lowerCase(tab?.id) === 'work steps' ?
        'import work steps from excel' : 'import items from excel'"
      @close-modal="closeModal"
      @refresh-table="refreshTable"
      :importLimit="setItemImportLimit ? setItemImportLimit : ''"
    ></excel-import>
  </div>
  <track-productivity v-if="trackModal"
    :trackModal="trackModal"
    :card="rowData"
    :current-run="currentRun"
    :run-index="currentRunINdex"
    @save="refreshCard"
    @close="trackModal = false"
  >
  </track-productivity>
  <runs-timer
    v-if="isWorkstepModalActive"
    :key="workstepId"
    :isActive="isWorkstepModalActive"
    :workstepId="workstepId"
    :isManualTimer="isManualTimer"
    :card="rowData"
    :isLoading="isWorkStepLoading"
    @close="isWorkstepModalActive = false"
    @cancel-timer="stopWatch($event, true, isManualTimer)"
    @save-timer="stopWatch($event, false, isManualTimer)"
  />
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  reactive,
  onMounted,
  toRefs,
  computed,
  ref,
  inject,
} from 'vue';
import _ from 'lodash';
import Users from '@/models/Users';
import Locations from '@/models/Locations';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import QtyOptions from '@/components/utils/QtyOptions';
import Company from '@/models/Companies';
import { BaseOrder, RunItemsCount } from '@/models/BaseOrder';
import LocalSort from '@/components/utils/LocalSort';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import ItemInPrefab from '@/components/modals/ItemInPrefab.vue';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';
import AddFromCatalog from '@/components/modals/AddFromCatalog.vue';
import AddFromCatalogMixin from '@/components/mixins/AddFromCatalogMixin';
import ExcelImport from '@/components/ExcelImport.vue';
import translator from '@/languages/translator';
import TrackProductivity from '@/components/modals/TrackProductivity.vue';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import ExcelMixin from '@/components/mixins/ExcelMixin';
import moment from 'moment';
import QtyInput from '@/components/fields/QtyInput.vue';
import ProductionManager from '@/models/ProductionManager';
import qaQcField from '@/components/table-fields/QAQCField.vue';
import { DialogProgrammatic } from '@/components/Dialog';
import UpdateBomQty from '../modals/UpdateBomQty.vue';
import Helper from '@/models/Helper.js'
import RunsTimer from '../modals/RunsTimer.vue';
import UtilityMixin from '../mixins/UtilityMixin';
import CardEditMixin from '../mixins/CardEditMixin';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default defineComponent({
  name: 'MfDetailManagerRowTab',
	emits:['updateItemQty'],
  props: {
    rowData: {
      type: Object,
      default: () => {},
    },
    rowIndex: Number,
    edit: {
      type: Boolean,
    },
    tab: {
      type: Object,
      default: () => {},
    },
    stage: String,
    refreshCard: Function,
  },
  components: {
    MfTable,
    TrackProductivity,
    ExcelImport,
    QtyInput,
    qaQcField,
    RunsTimer,
  },
  setup(props, { emit }) {
    const emitter = inject('emitter');
    const { tab, rowData } = props;
    const store = useStore();
    const toast = useToast();
    const { t } = translator;
    const managerTable = ref(null);
    const { poAddItemRows, PMAddItemRows, disableInput } = ProductionManagerMixin();
    const { addFromCatalog } = AddFromCatalogMixin();
    const { validateRun, addFiles } = ExcelMixin();
    const { getHoursMinutesSeconds } = UtilityMixin();
    const { disableStatus } = CardEditMixin();
    const errMsgForItemCount = 'Can not add more than 50 items to a Production Manager';
    const perPageCutItems = 50;
    const state = reactive({
      tableData: [],
      isLoading: false,
      allUsers: [],
      disableOwnerAndLoc: ['delivery', 'complete'],
      allLocations: [],
      runFilter: store.state.runFilter,
      maxQty: QtyOptions.inputProps.max,
      companyRuns: [],
      initialRiCopy: {},
      prefabList: [],
      trackModal: false,
      currentRun: {},
      currentRunIndex: null,
      excelModal: false,
      dates: [],
      saveExcelItem: null,
      validateExcelItem: null,
      selectedPrefab: '',
      refreshKey: 0,
      setItemImportLimit: '',
      itemQtyChanged: false,
      isWorkstepModalActive: false,
      workstepId: '',
      isManualTimer: false,
      isWorkStepLoading: false,
    });
    const maxHoursAllowed = 999;
    const openTrackModal = (run) => {
      state.trackModal = true;
      state.currentRun = run;
      state.currentRunIndex = _.findIndex(rowData.manager.runs, { _id: run._id });
    };
    const openRunsTimerModal = (data) => {
      state.isManualTimer = data.isManualTimer;
      state.workstepId = data.rowData._id || data.rowData.uuid;
      state.isWorkstepModalActive = true;
    }
    const filteredRuns = () => _.filter(rowData.manager.runs, (r) => !r.archived.value);
    const unUsedCompanyRuns = () => {
      state.companyRuns.forEach((r) => { r.copiedFrom = r.copiedFrom || r._id; });
      const curRuns = filteredRuns();
      return _.differenceBy(
        state.companyRuns, curRuns,
        (r) => r.name || r.copiedFromId || r.copiedFrom,
      );
    };
    const filterItems = () => {
      if (rowData.items.length > 0) {
        const data = LocalSort.filter(_.filter(rowData.items, (i) => !i.archived.value), {
          sortField: 'name',
          direction: 'asc',
        }, true);

        return data;
      }
      return [];
    };
    const itemLimit = () => {
      let key = 'prefab';
      if (rowData.isMM()) key = 'mm';
      if (rowData.isPM()) key = 'pm';
      if (rowData.isPO() || rowData.isProductionTemplate()) key = 'po';
      if(rowData.isPM() && rowData.isCutOrder) return 600;

      return store.state.itemLimits[key];
    };
    const maxAddable = () => {
      if(rowData?.isCutOrder) {
        if(rowData.totalCutItems === itemLimit()) {
          return perPageCutItems - filterItems().length;
        }
        return itemLimit() - rowData.totalCutItems;
      }
      return (itemLimit() - filterItems().length);
    }
    const stageItems = () => {
      if (rowData.stage === 'template') { return filterItems(); }
      const allowedStages = BaseOrder.appendShippingStages(props.stage);
      return _.filter(filterItems(), (i) => allowedStages.includes(i.stage));
    };

    const getRequiredDates = () => {
      let dates = [
        {
          kind: 'coordDate',
          label: 'Coordination',
        },
        {
          kind: 'deliverDate',
          label: 'Onsite',
        },
      ];
      if (rowData instanceof BaseOrder && rowData.isMM()) {
        dates = [
          {
            kind: 'orderBy',
            label: 'orderBy',
          },
          {
            kind: 'shipBy',
            label: 'shipBy',
          },
        ];
      }
      if (rowData instanceof BaseOrder && rowData.isPM()) {
        dates = [
          {
            kind: 'startDate',
            label: 'Start Date',
          },
          {
            kind: 'finishDate',
            label: 'Finish Date',
          },
        ];
      }
      if (rowData instanceof BaseOrder && (rowData.isPO() || rowData.isProductionTemplate())) {
        dates = [
          {
            kind: 'coordDate',
            label: 'Coordination',
          },
          {
            kind: 'deliverDate',
            label: 'Onsite',
          },
        ];
      }

      return dates;
    };

    function disableProdTrack(run) {
      if (rowData._customStage !== 'manufacturing') return true;
      return run.isTracked;
    }
    function updateRunItemCount(run) {
      for (const runItem of run.runItemsCount) {
        runItem.percComplete = run.stats.percComplete;
        runItem.riCompleted = run.isCompleted;
      }
    }
    function checkProductivity(val, data) {
      if (_.cloneDeep(data.isCompleted)) {
        _.set(data, 'stats.percComplete', 100);
      } else {
        _.set(data, 'stats.percComplete', 95);
      }
      updateRunItemCount(data);
    }
    function onChange(run, percKey) {
      if (!_.isNumber(run.stats.percComplete)) {
        run.stats.percComplete = 0
      }
      run.stats.percComplete = run.stats.percComplete > 100 ? 100 : run.stats.percComplete;
      if(percKey && !_.isEmpty(run.form?._id) && run.formStatus !== 'complete' && run.stats.percComplete > 95){
        run.stats.percComplete =  95 ;
        toast.warning('WorkStep cannot be marked 100% done before QA QC form is marked Complete')
      }
      run.isCompleted = run.stats.percComplete === 100;
      updateRunItemCount(run);
    }
    function onChangeisCompleted(run) {
      run.stats.percComplete = run.isCompleted == true ? 100 : 95;
      updateRunItemCount(run);
    }
    function updateAllQty(item) {
      item.qtyUpdated = true;
      if (item.autoHoursonQty < _.get(item, 'serials.unavailable', 1)) {
        toast.error(`New item quantities cannot be lower than the quantities that have already shipped. (${item.serials.unavailable})`);
      }
      item.autoHoursonQty = _.clamp(Math.floor(item.autoHoursonQty), _.get(item, 'serials.unavailable', 1), QtyOptions.inputProps.max);
      item.order.manager.runs.forEach((run) => {
        const idx = item.order.manager._runItemsIndexMap[run.uuid][item.uuid];
        if (idx >= 0) run.runItemsCount[idx].quantity.thisRun = item.autoHoursonQty;
      });
      if (item.autoHoursonQty > 0) {
        state.itemQtyChanged = true;
        emit('updateItemQty', state.itemQtyChanged);
        if (item.purpose === 'assembly' && props.rowData.hasGuid === true) {
          ModalProgrammatic.open({
            component: UpdateBomQty,
            trapFocus: true,
            canCancel: false,
            rootClass: 'modal-xs',
            events: {
              'refresh-table': async () => {
              },
              'save-card': async () => {
                _.set(props.rowData, 'updatedVia', 'manual');
                await props.rowData.save();
                await props.refreshCard();
                toast.warning('The quantity changes done here will be updated in BOM');
              },
              'refresh-cancel': async () => {
                await props.refreshCard();
              },
            },
          });
        }
      }
    }
    const loadAsyncData = async () => {
      const card = await ProductionManager.get({
        orderId: rowData._id,
        projectId: rowData.project._id,
      });
      let data = _.get(card, 'manager.runs');
      data = data.filter((item) => !item.archived?.value);
      if (tab.accessor === 'manager.runs' && state.runFilter !== 'all') {
        const currentOwner = store.state.userData.id;
        data = _.filter(data, (eachRun) => {
          eachRun.instanceOf = 'BaseOrder';
          if (state.runFilter.val === 'my-runs') {
            return eachRun.owner.user.id === currentOwner;
          } if (state.runFilter.val === 'my-pending') {
            return eachRun.owner.user.id === currentOwner && eachRun.completed === false;
          } if (state.runFilter.val === 'all-pending') {
            return eachRun.completed === false;
          }
          return true;
        });
      }
      return { data, total: data.length };
    };

    const loadData = () => {
      let data;
      if (tab.accessor === 'manager.runs') {
        data = _.get(rowData, tab.accessor);
        if(rowData.isCutOrder){
          for(const run of data){
            run.setTotalPlannedHrs();
          }
        }
      } else {
        data = LocalSort.filter(_.get(rowData, tab.accessor || 'items'), managerTable?.value?.sort);
      }
      data = data.filter((item) => !item.archived?.value);
      if (tab.accessor === 'items') {
        data.map((item) => {
          item.instanceOf = rowData.__t ? 'BaseOrder' : 'ShippingLabel';
          item.__t = rowData.__t;
          item._customStage = rowData._customStage;
          item.isDetailRowEnabled = rowData.isDetailRowEnabled;
          item.isPmCardView = rowData.isPmCardView;
          return item;
        });
      } else if (tab.accessor === 'manager.runs' && state.runFilter !== 'all') {
        const currentOwner = store.state.userData.id;
        data = _.filter(data, (eachRun) => {
          eachRun.instanceOf = 'BaseOrder';
          if (state.runFilter.val === 'my-runs') {
            return eachRun.owner.user.id === currentOwner;
          } if (state.runFilter.val === 'my-pending') {
            return eachRun.owner.user.id === currentOwner && eachRun.completed === false;
          } if (state.runFilter.val === 'all-pending') {
            return eachRun.completed === false;
          }
          return true;
        });
      }
      return data;
    };
    const addDefaultRi = (run) => {
      for (const [i, item] of stageItems().entries()) {
        if (i < BaseOrder.runItemLimit) {
          run.runItemsCount.push(rowData.manager.getDefaultRi({ item, run }));
        }
        if (run.runItemsCount.push.length === BaseOrder.runItemLimit) break;
      }
      run.isEditing = true;
      rowData.manager.runItemsIndexMap();
    };
    const refreshTable = async () => {
      await managerTable.value.refreshTable();
    };
    const deleteRunForms = (param) => {
      for (const doc of param.data.simpleFiles) {
        if (doc.type === 'form') {
          rowData.deleteForm({ doc });
        }
      }
    };

    const validateExcelRun = (obj) => {
      let errMsg = '';
      errMsg = validateRun(obj, state.allLocations);
      if (rowData.manager.pTrackEnabled && !errMsg) {
        const isPTRun = _.find(unUsedCompanyRuns(), (cRun) => cRun.name === obj.name);
        if (!isPTRun) {
          errMsg = 'Either it\'s not a part of company runs or has already been used';
          return errMsg;
        }
      }
      return errMsg;
    };

    const saveExcelRun = (obj) => {
      if (obj.length > unUsedCompanyRuns().length && rowData.manager.pTrackEnabled) {
        throw new Error(`Please select an excel with ${unUsedCompanyRuns().length} Runs`);
      }
      const allCompUsers = [];
      const allLocs = [];
      _.forEach(state.allLocations, (loc) => {
        allLocs.push(...loc.val);
      });
      _.forEach(state.allUsers, (user) => {
        allCompUsers.push(...user.val);
      });
      _.map(obj, (run) => {
        let count = 0;
        let loc = _.find(allLocs,
          (location) => _.startCase(location.name) === _.startCase(run.location));
        const locNames = _.map(allLocs, (location) => location.name);
        if (loc) {
          _.forEach(locNames, (locName) => { if (locName === loc.name) { count++; } });
          if (count > 1) { loc = ''; }
        }
        let user = _.find(allCompUsers, (usr) => usr.email === run.owner);
        user = user || store.state.userData;
        const temp = rowData.manager.addRun();
        const names = _.map(unUsedCompanyRuns(), (compRun) => compRun.name.toLowerCase());
        if (rowData.manager.pTrackEnabled || rowData.__t === 'ProdTemplates') {
          const unUsedRuns = unUsedCompanyRuns();
          if (names.includes(run.name.toLowerCase()) && !_.isEmpty(run.name)) {
            _.map(unUsedRuns, (compRun) => {
              if (compRun.name.toLowerCase() === run.name.toLowerCase()) {
                temp.name = compRun.name;
                temp.copiedFromId = compRun.copiedFromId || compRun._id;
              }
            });
          } else {
            temp.name = unUsedRuns[0].name;
            temp.copiedFromId = unUsedRuns[0].copiedFrom;
          }
        } else temp.name = run.name;

        if (rowData.manager.pTrackEnabled && rowData._customStage !== 'manufacturing') {
          temp.markCompleteDisabled = true;
        }
        if (!_.isEmpty(run.owner)) {
          temp.owner.user = { _id: user._id };
          temp.owner.user.name = user.name;
        }
        if (!_.isEmpty(run.location) && loc) {
          temp.location = { _id: loc._id };
          temp.location.name = loc.name;
        }
        temp.hours = Helper.getTotalSeconds(run, temp);
        if (!_.isUndefined(run.startDate) && moment(run.startDate).isValid()) {
          temp.newDate('coord');
          temp.simpleDates.coord.value = moment(run.startDate);
        }
        if (!_.isUndefined(run.finishDate) && moment(run.finishDate).isValid()) {
          temp.newDate('deliver');
          temp.simpleDates.deliver.value = moment(run.finishDate);
        }
        addDefaultRi(temp);
        run = addFiles(run, temp);
      });
      toast.success('Imported Runs Successfuly');
      refreshTable();
    };

    const cutOrderItemLimitCheck = (card) => {
      const sendErrMsg = card.totalCutItems === itemLimit() ? filterItems().length === perPageCutItems : false;
      if(sendErrMsg){
         return `Can not add more than ${itemLimit()} items to a Production Manager`;
      }
      return '';
    }

    const onTableCellClicked = async (params) => {
      const { event, type, data } = params;
      if (params.type === 'quantityUpdate' && props.rowData.stage === 'coordination') {
        state.itemQtyChanged = true;
        emit('updateItemQty', state.itemQtyChanged);
        if (props.rowData.items[0].purpose === 'assembly' && props.rowData.hasGuid === true) {
          ModalProgrammatic.open({
            component: UpdateBomQty,
            trapFocus: true,
            canCancel: false,
            rootClass: 'modal-xs',
            events: {
              'refresh-table': async () => {
              },
              'save-card': async () => {
                await props.rowData.save();
                await props.refreshCard();
                toast.warning('The quantity changes done here will be updated in BOM');
              },
              'refresh-cancel': async () => {
                await props.refreshCard();
              },
            },
          });
        }
        return;
      }
      if (event && (event === 'run-addNew' || event === 'run-excelImport')) {
        if (rowData.manager.pTrackEnabled && unUsedCompanyRuns().length === 0) {
          toast.success(t('Cannot add more work steps,since all pre-defined work steps are in use.'));
          return;
        }
      }
      if (event === 'run-excelImport') {
        state.setItemImportLimit = store.state.runsLimit.pm;
      }
      if (event && event === 'run-addNew') {
        if (filteredRuns().length > 99) {
          toast.error('Can not have more than 100 Runs');
          return;
        }
        const run = rowData.manager.addRun();
        if (run.stats && !run.stats.hasOwnProperty('percComplete')) {
          run.stats.percComplete = 0;
        }
        if (rowData.manager.pTrackEnabled) {
          if (rowData._customStage !== 'manufacturing') {
            // Disable checkbox for newly added runs in PT
            run.markCompleteDisabled = true;
          }
          // eslint-disable-next-line
          ({ name: run.name, copiedFrom: run.copiedFromId } = unUsedCompanyRuns()[0]);
        }
        addDefaultRi(run);
      }
      if (event && event === 'run-excelImport') {
        state.saveExcelItem = saveExcelRun;
        state.validateExcelItem = validateExcelRun;
        state.excelModal = true;
      }
      const prefabImportKeys = {
        addFromCatalog: 'assembly',
        addNew: 'new',
        excelImport: 'excel',
        addFromPrefab: 'old',
      };
      if (['addNew', 'addFromCatalog', 'addFromPrefab'].includes(event)) {
        
        if(rowData.isCutOrder){
          const itemLimitMsg =  cutOrderItemLimitCheck(rowData);
          if(itemLimitMsg) {
            return toast.error(itemLimitMsg);
          }
        }
        if (rowData.items.length >= itemLimit()) {
          return toast.error(errMsgForItemCount);
        }
        if (!prefabImportKeys[event]) return;
        ModalProgrammatic.open({
          component: ItemInPrefab,
          props: {
            module: 'Prefabs',
            card: rowData,
            kind: prefabImportKeys[event],
          },
          rootClass: getModalRootClass(prefabImportKeys[event]),
          trapFocus: true,
          canCancel: false,
          events: {
            close: async (items, prefabList) => {
              state.isLoading = true;
              if (rowData.__t === 'ProductionOrder' && !rowData.isManager) {
                poAddItemRows({
                  rowData, items, prefabList, refreshTable,
                });
              } else {
                PMAddItemRows({
                  rowData, items, prefabList, refreshTable,
                });
              }
              state.isLoading = false;
            },
            openCatalogList: (pId, pList) => {
              state.selectedPrefab = pId;
              state.prefabList = pList;
              ModalProgrammatic.open({
                component: AddFromCatalog,
                props: {
                  module: rowData.__t,
                  projectId: rowData.project._id,
                  maxAddable: maxAddable(),
                  title: 'Select Items From Catalog',
                },
                trapFocus: true,
                canCancel: false,
                rootClass: 'modal-sm',
                events: {
                  close: async (itemsToAdd) => {
                    if (rowData.items.length + itemsToAdd.length > itemLimit()) {
                      return toast.error(errMsgForItemCount);
                    }
                    state.isLoading = true;
                    const { selectedPrefab, prefabList } = state;
                    if (!_.isEmpty(itemsToAdd)) {
                      await addFromCatalog({
                        itemsToAdd,
                        rowData,
                        selectedPrefab,
                        prefabList,
                        refreshTable,
                      });

                      if(rowData.isCutOrder) {
                        state.isLoading = false;
                        emitter.emit('itemsToolsEvent', { event: 'saveCutCard' });
                      }
                    }
                    if(!rowData.isCutOrder) state.isLoading = false;
                  },
                },
              });
            },
          },
        });
      } else if (event === 'excelImport') {
        state.setItemImportLimit = itemLimit() - rowData.items.length;
        if(rowData.isCutOrder){
          const itemLimitMsg =  cutOrderItemLimitCheck(rowData);
          if(itemLimitMsg) {
            return toast.error(itemLimitMsg);
          }
          state.setItemImportLimit = maxAddable();
        } else if (rowData.items.length === itemLimit()) {
          return toast.error(errMsgForItemCount);
        }
        if (!prefabImportKeys[event]) return;
        ModalProgrammatic.open({
          component: ItemInPrefab,
          props: {
            module: 'Prefabs',
            card: rowData,
            kind: prefabImportKeys[event],
          },
          rootClass: getModalRootClass(prefabImportKeys[event]),
          trapFocus: true,
          canCancel: false,
          events: {
            close: async (selectedPrefab) => {
              _.set(state, 'selectedPrefab', selectedPrefab);
              if (!_.isUndefined(selectedPrefab)) {
                state.saveExcelItem = null;
                state.validateExcelItem = null;
                state.excelModal = true;
              }
            },
          },
        });
      }
      if (type && type === 'showDetails') {
        openTrackModal(data);
      }
      if (type && type === 'delete') {
        if (params.data && params.data._place === 'run') {
          if (filteredRuns().length === 1) {
            toast.warning('Can not delete the last run. All items must be part of at least one run');
            return;
          }
          params.data.delete(params.data);
          rowData.manager.runItemsIndexMap();
        } else if (params.data && params.data._place === 'item') {
          const item = params.data;
          if (rowData.items.length === 1 && props.rowData.stage === 'coordination') {
            toast.warning('Order will be archived and moved back to planning stage');
          }
          deleteRunForms(params);
          // delete an item from all runs before removing the item itself.
          item.order.manager.runs.forEach((run) => {
            const idx = item.order.manager._runItemsIndexMap[run.uuid][item.uuid];
            if (idx >= 0) run.runItemsCount.splice(idx, 1);
          });
          // force delete an item if it is from planning stage, instead of archiving it
          params.data.delete({ force: (data.stage === 'planning') });
          item.order.manager.runItemsIndexMap();
          refreshTable();
        }
      }
      if (type && type === 'runsTimerModal') {
        openRunsTimerModal(data);
      }
      if (params.event === 'data-changed') {
        // distribute run actualHrs to runItems
        if (params.prop === 'stats.actualHrs' && params.value >= 0) {
          const run = params.data;
          const riActualHrs = params.value / run.runItemsCount.length;
          run.runItemsCount.forEach((ri) => { ri.actualHrs = riActualHrs; });
        }
      }
      if (params.type === 'run-filter') {
        state.runFilter = _.get(params, 'selectedOption', {});
        state.refreshKey++;
        return;
      }
      if (['itemPagination', 'itemSearch', 'showCompletedItems'].includes(params.type)) {
        const opts = {
          itemPagination: {
            event: 'setPagination',
            params: params.optType,
          },
          itemSearch: {
            event: 'setPagination',
            params: {search : params.optType},
          },
          showCompletedItems: {
            event: 'showCompletedItems',
            params: {hideCompleted: (props.rowData?.showCompletedItems) ? 1 : 0 }
          }
        }
        const eventObj = opts[params.type];
        eventObj.refreshToolbar = managerTable?.value?.refreshToolbar;
        emitter.emit('itemsToolsEvent', eventObj);
        return;
      }
      if (params.event !== 'data-changed') await refreshTable();
      CardDirtyBus.emit('setCustomDirty');
    };

    const closeModal = (compProps) => {
      state.excelModal = false;
      if (props.rowData?.isCutOrder && !compProps?.noSave) {
        const { invalidDatas, fileName } = compProps
        emitter.emit('itemsToolsEvent', { event: 'saveCutCard', params: { invalidDatas, fileName } });
      }
    };

    onMounted(async () => {
      const comps = rowData.getInvolvedCompanies();
      let locs = [];
      if (tab.accessor === 'manager.runs') {
        store.dispatch('getCompanyRuns');
        [state.allUsers, locs] = await Promise.all([
          Users.getGroupedProjectLinkedUsers(comps, rowData.project._id),
          Locations.allLocationForUser({
            companyIds: comps,
            projectId: rowData.project._id,
          }),
        ]);
        state.companyRuns = store.state.companyRuns;
        state.allLocations = Locations.groupLocations(locs, store.state.companyData);
      }
      state.dates = getRequiredDates();
    });

    const getApiMode = () => tab.accessor === 'manager.runs' && _.isEmpty(rowData.manager.runs);

    const getLoadData = () => {
      if (getApiMode()) return loadAsyncData;
      return loadData;
    };

    const addedForm = (params) => {
      if (!_.isEmpty(store.state.reOrderRunsName)) {
        const { runs } = rowData.manager;
        const reOrderRuns = [];
        _.forEach(store.state.reOrderRunsName, (uuid) => {
          reOrderRuns.push(runs.find((run) => run.uuid === uuid));
        });
        _.set(rowData, 'manager.runs', reOrderRuns);
      }
      refreshTable();
    };
    const getPurpose = computed(() => _.get(props.rowData, 'purpose', ''));

    const isItemCheckDisabled = computed(() => ((rowData.isDetailRowEnabled
        && !tab.isPmCardView
        && rowData.stage === 'manufacturing')
        || rowData.markCompleteDisabled));

    const isDisabledItemCheck = (item) => {
      if (item.stage === 'detailing'
        || (['manufacturing', 'detailing',
          'manager-edit-qa'].includes(store.state.activeScreen))) return true;
      return false;
    };

    const isItemCheckDisabledOnQc = (item) => {
      const runWithNonCompleteForm = _.find(item.runs, (run) => {
        if (!_.isEmpty(run.form) && run.formStatus !== 'complete' && run.form._id) {
          return true;
        }
        return false;
      });
      return !_.isEmpty(runWithNonCompleteForm);
    };

    const stopWatch = (async (run, isTimerDiscarded = false, isManualTimer = false) => {
      state.isWorkStepLoading = true;
      let res = {};
      const params = {
        orderId: rowData._id,
        runUuid: run.uuid,
      };
      if (isTimerDiscarded) {
        if (!isManualTimer) {
          const confirmParam = {
            title: 'DELETE ACTIVE WORK TIMER',
            message: "<div class='mb-5'>Deleting active work timer will stop the recording and discard the current recorded time.</div><div>Are you sure you want to delete this active timer?</div>",
            okButton: 'Cancel Timer',
            cancelButton: 'Back',
            type: 'danger',
            confirmButtonStyle: 'isSolid',
            cancelBtnIsOutlined: true,
            onConfirm: async () => {
              state.isLoading = true;
              try {
                if (rowData.manager?.pt?.[run.uuid]) delete rowData.manager.pt[run.uuid];
                await saveData();
              } catch (error) {
                toast.error('Error while discarding timer');
              } finally {
                state.isLoading = false;
              }
            },
          };
          DialogProgrammatic.confirm(confirmParam);
        }
      } else if (isManualTimer) {
        run.fieldsDirty = true;
        run.timerStopped = true;
        await saveTimerStatus(run);
      } else {
        res = await ProductionManager.stopTimer(params);
        rowData.manager.pt = res.pt;
        const runIndex = _.findIndex(rowData.manager.runs, (mrun) => mrun.uuid === run.uuid);
        rowData.manager.runs[runIndex] = run;
        const managerPT = _.get(
          rowData.manager,
          `pt.${run.uuid}`, {},
        );
        const startTimer = _.get(managerPT, 'startTimer', 0);
        const stopTimer = _.get(managerPT, 'stopTimer', 0);
        const clockSeconds = (moment(stopTimer).diff(startTimer, 'seconds'));
        const { hours, minutes, seconds } = getHoursMinutesSeconds(clockSeconds);
        run.clockHrs = hours;
        run.clockMins = minutes;
        run.clockSeconds = seconds;
        run.fieldsDirty = true;
        run.timerStopped = true;
        await saveTimerStatus(run);
      }
      state.isWorkStepLoading = false;
      state.isWorkstepModalActive = false;
      return res;
    });

    const isStopWatchOn = ((run) => {
      const managerPT = _.get(
        rowData,
        `manager.pt.${run.uuid}`, {},
      );
      return _.get(managerPT, 'startTimer', false)
        && !_.get(managerPT, 'stopTimer', false);
    });


    const validateAndSet = ((val, showToast) => {
      const workstep = _.cloneDeep(val);
      let saveRun = true;
      const stopWatch = isStopWatchOn(workstep);
      if (!stopWatch) {
        const clockTime = ((parseInt(workstep.clockHrs || 0, 10) * 3600)
          + (parseInt(workstep.clockMins || 0, 10) * 60) + (parseInt(workstep.clockSeconds || 0, 10)));
        if (!workstep.workers || workstep.workers <= 0) {
          workstep.workers = 1;
        }
        const actualHrs = parseInt(_.get(workstep, 'stats.actualHrs', 0) || 0, 10)
          + parseInt((clockTime * workstep.workers), 10);
        workstep.actualHrs = actualHrs;
      }
      if ((parseInt(workstep.actualHrs / 3600, 10)) > maxHoursAllowed) {
        if (showToast) {
          toast.error(`Entered hours maximum is ${maxHoursAllowed}`);
        }
        saveRun = false;
      }
      val.actualHrs = workstep.actualHrs;
      val.percComplete = workstep.percComplete;
      if (saveRun) {
        const timer = _.get(
          rowData.manager,
          `pt.${workstep.uuid}`, {},
        );
        if (timer && timer.startTimer && timer.stopTimer) {
          delete rowData.manager.pt[workstep.uuid];
        }
      }
      return saveRun;
    });

    const saveData = (async () => {
      let res = null;
      let updateStatus = false;
      _.forEach(rowData.manager.runs, (run) => {
        if (run.completed) {
          _.set(rowData.manager, `pt.${run.uuid}`, {});
        }
        if (run.stats.actualHrs) updateStatus = true;
      });
      if (updateStatus) rowData.status = 'in-progress';
      res = await rowData.save();
      rowData.lastModified.at = res.lastModified.at;
      if (res._id) {
        toast.success('Work data saved');
      }
      return;
    });

    const saveTimerStatus = (async (workstep) => {
      let saveRun = false;
      let showToast = true;
      for (const run of rowData.manager.runs) {
        if (run.uuid === workstep.uuid) {
          const passedValidation = validateAndSet(run, showToast);
          if (passedValidation) {
            run.stats.actualHrs = run.actualHrs;
            if (run.stats.actualHrs > 0) run.isTracked = true;
            else run.isTracked = false;
            saveRun = true;
          }
        }
      }
      if (!saveRun) return;
      await saveData();
    });
    const isCheckboxDisabled = (workstep, val, percKey) => {
      const status = disableStatus(workstep?.order?.status);
      if (!_.isUndefined(val) && workstep.completed) return true;
      if (workstep && _.isEmpty(workstep.form?._id)) {
        return status? true : false;
      }
      if (workstep && (workstep?.formStatus === 'complete')) {
        return status? true : false;
      }
      if(percKey)return false;
      return true;
    };

    const getModalRootClass = (e) => {
      const classArr = [];
      if (e === 'old') {
        classArr.push('modal-sm');
      } else {
        classArr.push('modal-xs')
      }
      if (rowData?.isCutOrder) {
        classArr.push('noOverlay')
      }
      return classArr.join(' ');
    };

    return {
      getLoadData,
      getApiMode,
      ...toRefs(state),
      onChange,
      disableProdTrack,
      checkProductivity,
      updateAllQty,
      onTableCellClicked,
      managerTable,
      openTrackModal,
      getRequiredDates,
      closeModal,
      refreshTable,
      getPurpose,
      isItemCheckDisabled,
      isDisabledItemCheck,
      addedForm,
      isItemCheckDisabledOnQc,
      disableInput,
      stopWatch,
      onChangeisCompleted,
      isCheckboxDisabled,
    };
  },
});
</script>
