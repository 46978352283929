import { some, find } from 'lodash';
import validateLmv from '../utils/lmv3dotValidation';

const orderStages = ['planning', 'coordination', 'detailing',
  'manufacturing', 'qa']; // **Please do not change the order of the stages in this array.**

function isDateEditable(rowData, editableStages = []) {
  return !(editableStages.includes(rowData.stage)
    || (rowData.stage === 'mixed'
    && some(rowData.items, (item) => editableStages.includes(item.stage))));
}

export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  isDetailed: true,
  stickeyHeaders: true,
  rearrangeCols: false,
  permissionModule: 'scm',
  detailRowComponent: 'mf-detail-row',
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
      isLine: true,
      tooltip: 'Customise Columns',
    }],
  },
  toolBar: {
    // name: for drop down label, label: for action button label
    bulkActions: () => {
      const opts = [{
        name: 'Change Dates',
        event: 'massUpdateDates',
        label: 'Change Dates',
      },
      {
        name: 'Print QR Labels',
        event: 'bulkQrPrint',
        label: 'Print QR',
        refreshOnCancel: true,
      },
      {
        name: 'Edit Description',
        event: 'editOrderIds',
        label: 'Edit Order IDs',
      },
      {
        name: 'Delete Orders', event: 'removeOrders', label: 'Delete',
      },
      ];
      return opts;
    },
    hideSearchBar: false, // by default let the search bar be available for the table
    buttons: () => [{
      event: 'excelExport',
      icon: 'icon-exportexcel',
      tooltip: 'Export to Excel',
    }],
  },
  fields: [
    {
      name: 'field-pause-restart',
      sortField: 'name',
      width: '400px',
      title: 'Production Orders',
      prop: 'name',
      sticky: true,
      id: 'name',
      alwaysVisible: true,
      isClickable: (rowData) => rowData?.isDisabled,
      eventType: 'openCard',
      searchField: false, // to give a search bar for the field
      searchable: true, // to place any custom field in the header of the column like for below
      bulkActionButtonField: true,
      inputProps: {
        type: 'text',
        stageIcons: true,
        icon: {
          pauseIcon: 'icon-pause',
          restartIcon: 'icon-restart',
        },
      },
    },
    {
      name: 'field-generic-input',
      sortField: 'financialId',
      width: '152px',
      default: false,
      title: 'Description',
      id: 'customId',
      prop: 'financialId',
      inputProps: {
        type: 'text',
      },
    },
    {
      name: 'field-generic-input',
      sortField: 'uniqueOrderId.value',
      width: '152px',
      default: false,
      title: 'Order ID',
      id: 'uniqueOrderId',
      prop: 'uniqueOrderId.value',
      inputProps: {
        type: 'text',
      },
    },
    {
      name: 'field-kit-id',
      sortField: 'customId',
      width: '152px',
      title: 'Kit ID',
      prop: 'customId',
      id: 'kitId',
      isDisabled: (rowData) => rowData?.isDisabled,
      defaultVisible: true,
      inputProps: {
        type: 'text',
        disableEdit: (rowData) => rowData.purpose !== 'kit' || rowData.stage !== 'planning',
      },
    },
    {
      title: '',
      name: 'lmvIcon',
      width: '64px',
      id: 'lmvIcon',
      defaultVisible: true,
      customHeader: true,
      customSlotHeader: true,
      inputProps: {
        icon: 'icon-linked-model',
      },
    }, {
      name: 'project',
      id: 'project',
      title: 'Project',
      width: '296px',
      show: true,
      sortField: 'project',
      defaultVisible: true,
      searchable: true,
      filterText: 'Projects',
    }, {
      title: 'Stage',
      name: 'field-stage',
      prop: 'stage',
      searchable: true,
      filterText: 'Stages',
      id: 'stage',
      width: '152px',
      defaultVisible: true,
      alwaysVisible: false,
      sortField: 'stage',
      cardProps: {
        cardType: '__t',
      },
    }, {
      name: 'field-stage',
      id: 'status',
      title: 'Status',
      width: '196px',
      prop: 'status',
      show: true,
      sortField: 'status',
      searchable: true,
      filterText: 'Status',
      defaultVisible: true,
      isStatus: true,
      isStaticColor: true,
    },
    {
      title: 'Owner',
      name: 'field-user-select',
      prop: 'owner.user',
      width: '296px',
      sortField: 'effectiveOwner.user',
      id: 'owners',
      exportId: 'owner',
      searchable: true,
      filterText: 'Owners',
      defaultVisible: true,
      cardProps: {
        companyId: 'created.by.company._id',
        projectId: 'project._id',
        isEditing: 'isEditing',
      },
      inputProps: {
        getUserName: (row) => (row.isManager ? 'manager.owner.user' : 'owner.user'),
      },
    },
    {
      name: 'company',
      id: 'companies',
      title: 'Company',
      width: '296px',
      show: true,
      sortField: 'name',
      searchable: true,
      filterText: 'Companies',
      defaultVisible: false,
    }, {
      title: 'Location',
      name: 'field-location-select',
      prop: 'manager.location',
      width: '296px',
      sortField: 'manager.location',
      id: 'locations',
      exportId: 'location',
      defaultVisible: true,
      searchable: true,
      filterText: 'Locations',
      cardProps: {
        companyId: 'created.by.company._id',
        projectId: 'project._id',
        isEditing: 'isEditing',
      },
    },
    {
      title: 'Level',
      name: 'field-lvl-zone',
      isDisabled: false,
      sortField: 'level',
      width: '296px',
      id: 'level',
      prop: 'level',
      searchable: true,
      searchField: 'level',
      searchPlaceHolder: 'Level',
      filterText: 'Level',
      inputProps: {
        options: 'levels',
      },
    }, {
      title: 'Zone',
      name: 'field-lvl-zone',
      isDisabled: false,
      sortField: 'zone',
      width: '296px',
      id: 'zone',
      prop: 'zone',
      searchField: 'zone',
      searchable: true,
      searchPlaceHolder: 'Zone',
      filterText: 'Zone',
      inputProps: {
        options: 'zones',
      },
    }, {
      name: 'totalItems',
      prop: 'totalItems',
      width: '112px',
      title: 'Total Items',
      sortField: 'totalItems',
      id: 'totalItems',
      classRight: 'is-pulled-right',
      iPadVisible: true,
      defaultVisible: true,
    }, {
      title: 'Items (% Done)',
      name: 'field-progress-circle',
      prop: 'manager.stats.itemPerc',
      sortField: 'manager.stats.itemPerc',
      searchable: true,
      width: '112px',
      id: 'itemPercentComplete',
      isDisabled: true,
      defaultVisible: true,
      alwaysVisible: false,
    },
    {
      title: 'Work Steps (% Done)',
      name: 'field-progress-circle',
      prop: 'manager.stats.runsPerc',
      sortField: 'manager.stats.runsPerc',
      searchable: true,
      width: '112px',
      id: 'runPercentComplete',
      isDisabled: true,
      defaultVisible: true,
      alwaysVisible: false,
    }, {
      name: 'plannedHrs',
      prop: 'manager.stats.plannedHrs',
      sortField: 'manager.stats.plannedHrs',
      width: '112px',
      title: 'Planned Hours',
      id: 'plannedHrs',
      iPadVisible: true,
      defaultVisible: true,
    }, {
      name: 'actualHrs',
      prop: 'manager.stats.actualHrs',
      sortField: 'manager.stats.actualHrs',
      width: '112px',
      title: 'Actual Hours',
      id: 'actualHrs',
      iPadVisible: true,
      defaultVisible: true,
    }, {
      name: 'field-date',
      sortField: 'dates.coord',
      width: '152px',
      title: 'Coordinate By',
      prop: 'date',
      id: 'coordination',
      searchable: true,
      defaultVisible: true,
      dateFilter: 'coord',
      inputProps: {
        type: 'mf-date',
        kind: 'coord',
        max: ['poDetailBy', 'poManufactureBy', 'poQaBy', 'deliver'],
        disableEdit: (rowData) => isDateEditable(rowData, orderStages.slice(0, 2)),
      },
    }, {
      name: 'field-date',
      sortField: 'dates.detailBy',
      width: '152px',
      title: 'Detail By',
      prop: 'date',
      id: 'detailBy',
      searchable: true,
      defaultVisible: true,
      dateFilter: 'detailBy',
      inputProps: {
        type: 'mf-date',
        kind: 'detailBy',
        min: 'coord',
        max: ['manufactureBy', 'qaBy', 'deliver'],
        isManager: true,
        disableEdit: (rowData) => isDateEditable(rowData, orderStages.slice(1, 3)),
      },
    }, {
      name: 'field-date',
      sortField: 'dates.manufactureBy',
      width: '152px',
      title: 'Manufacture By',
      prop: 'date',
      id: 'manufactureBy',
      searchable: true,
      defaultVisible: true,
      dateFilter: 'manufactureBy',
      inputProps: {
        type: 'mf-date',
        kind: 'manufactureBy',
        min: ['detailBy', 'coord'],
        max: ['qaBy', 'deliver'],
        isManager: true,
        disableEdit: (rowData) => isDateEditable(rowData, orderStages.slice(1, 4)),
      },
    }, {
      name: 'field-date',
      sortField: 'dates.deliver',
      width: '152px',
      title: 'Onsite',
      prop: 'date',
      id: 'onSiteBy',
      defaultVisible: true,
      iPadVisible: true,
      searchable: true,
      dateFilter: 'deliver',
      inputProps: {
        type: 'mf-date',
        kind: 'deliver',
        min: ['qaBy', 'manufactureBy', 'detailBy', 'coord'],
        disableEdit: (rowData) => isDateEditable(rowData, orderStages),
      },
    },
    {
      name: 'lastModified',
      sortField: 'lastModified.at',
      id: 'modified',
      width: '152px',
      title: 'Modified',
      searchable: true,
      classRight: 'is-pulled-right',
      defaultVisible: true,
      dateFilter: 'modified',
      show: true,
    }, {
      name: 'field-date',
      sortField: 'dates.qaBy',
      width: '152px',
      title: 'QA/QC By',
      prop: 'date',
      id: 'qaBy',
      searchable: true,
      dateFilter: 'qaBy',
      inputProps: {
        type: 'mf-date',
        kind: 'qaBy',
        min: ['manufactureBy', 'detailBy', 'coord'],
        max: 'deliver',
        isManager: true,
        disableEdit: (rowData) => isDateEditable(rowData, orderStages.slice(1, 5)),
      },
    },
    {
      name: 'field-notes-icon',
      title: 'Notes',
      prop: 'notes',
      defaultVisible: true,
      width: '64px',
      id: 'notes',
      inputProps: {
        isItem: false,
      },
    },
    {
      title: 'Actions',
      name: 'table-field-actions',
      alwaysVisible: true,
      id: 'action',
      width: '112px',
      sticky: true,
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      hideFromChooser: true,
      searchable: true,
      clearBtn: true,
      permissionModule: 'scm',
      permissionKey: 'production-status',
      inputProps: {
        options(card) {
          const opts = [];
          const cardStage = card.getCardStage();
          if (!['delivery', 'complete', 'mixed'].includes(card?.stage)) {
            opts.push({
              icon: 'icon-removedelete',
              label: 'Delete',
              event: 'remove',
            });
          }
          if (!['planning', 'preparation', 'coordination'].includes(cardStage)) {
            opts.push({
              icon: 'fa fa-qrcode',
              label: 'Print QR',
              event: 'printQr',
            });
          }
          if (cardStage) {
            opts.push({
              icon: 'icon-activityhistory',
              label: 'History',
              event: 'activity',
            });
          }
          if (validateLmv.validate3dots(card.items)) {
            opts.push({
              icon: 'icon-lmv',
              label: 'View Model',
              event: 'lmv',
            });
          }
          return opts;
        },
        showEdit: (rowData) => {
          if (rowData.stage === 'complete') {
            return false;
          }
          return true;
        },
        editPermission: (params) => {
          if (!find(params.allProjects, { _id: params.rowData.project._id })) {
            return true;
          }
          return false;
        },
      },
    },
  ],
};
