<template>
    <div class="">
        <mf-table
                ref="materialsTable"
                :key="loadTable"
                :tableProps="tab.tableProps"
                :tab="tab"
                :loadData="loadData"
                :hideGutter="true"
                :poMaterials="materials"
                @cellClicked="onTableCellClicked"
                :isLocallyFiltered="true"
                :rowData="rowData"
        >
            <template v-slot:qty-consumed="{ rowData : item }">
                <qty-input v-model.number="item.qtyToConsume"
                           @input="changeMaterialItem({item, type: 'qtyToConsume', isCutOrder })"
                           @update="(val) => updateQty(val, item, 'qtyToConsume')"
                           :value="(isCutOrder ? item.qtyToConsume : (item.qtyToConsume + item.qtyConsumed))"
                           :roundTo="4" :max="isCutOrder ? 9999 : calculateMax(item, 'qtyToConsume', rowData)"
                           :isDisabled="qtyDisabled(item)"
                           :min="item.qtyConsumed"
                           >
                </qty-input>
            </template>
            <template v-slot:qty-ship="{ rowData : item }">
                <qty-input v-model.number="item.qtyToShip"
                           @input="changeMaterialItem({item, type: 'qtyToShip', isCutOrder })"
                           @update:value="(val) => updateQty(val, item, 'qtyToShip')"
                           :value="item.qtyToShip"
                           :roundTo="4" :max="calculateMax(item, 'qtyToShip', rowData)"
                           :isDisabled="qtyDisabled(item)"
                           >
                </qty-input>
            </template>
        </mf-table>
    </div>
</template>

<script>
import {
  defineAsyncComponent, defineComponent, reactive, ref, computed, toRefs, inject,
} from 'vue';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import AddFromInventory from '@/components/modals/AddFromInventory.vue';
import LinkRequestingOrders from '@/components/modals/LinkRequestingOrders.vue';
import Helper from '@/models/Helper';
import Order from '@/models/Orders';
import QtyInput from '@/components/fields/QtyInput.vue';
import _ from 'lodash';
// eslint-disable-next-line import/extensions
import ManagerRemainderMixin from '@/components/mixins/ManageRemainderMixin.js';
import { DialogProgrammatic } from '@/components/Dialog';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import LocalSort from '@/components/utils/LocalSort';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';
import ProductionManager from '@/models/ProductionManager';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));
const toast = useToast();

export default defineComponent({
  props: {
    materialsData: [],
    rowData: Object,
    rowIndex: Number,
    edit: {
      type: Boolean,
    },
    tab: {
      type: Object,
      default: () => {},
    },
    BOMOrders: [],
    refreshCard: Function,
  },
  components: { MfTable, QtyInput },
  setup({
    rowData, materialsData, BOMOrders, refreshCard, tab,
  }, { emit }) {
    const cardLoader = inject('showCardLoader');
    const store = useStore();
    const { changeMaterialItem, validateQTy, calculateMax } = ManagerRemainderMixin();
    const { createBOMForOrders } = ProductionManagerMixin();
    const materialsTable = ref(null);
    const state = reactive({
      tableData: [],
      loadTable: 0,
      orders: [],
      data: {},
      materials: [],
      isLoading: false,
      isAvailable: false,
      isCutOrder: rowData.isCutOrder
    });
    const qtyDisabled = (rowDataObj) => {
      if (['qa', 'delivery', 'complete'].includes(rowData._customStage)) { return true; }
      if (rowData.purpose === 'assembly') { return true; }
      if (['detailing', 'manufacturing', 'coordination'].includes(store.state.activeScreen)) { return true; }
      if (rowDataObj.qtyToShipOld === 0 && rowDataObj.quantity === 0) { return true; }
      return false;
    };

    const updateMaterialsData = (isDirty) => {
      materialsData = isDirty ? [] : state.materials;
      emit('updateMaterialsData', materialsData);
    };

    const updateReserveStatus = (() => {
      if (materialsTable.value?.mfToolbar) {
        const idx = _.findIndex(materialsTable.value.mfToolbar.toolButtons, (button) => button.event === 'reserveMaterials');
        if (idx !== -1) {
          materialsTable.value.mfToolbar.toolButtons[idx].isDisabled = !state.isAvailable;
        }
      }
    });
    const loadData = async (commonParams) => {
      let data;
      let total;
      if (_.isEmpty(materialsData)) {
        state.isLoading = true;
        const list = await Helper.getMaterialItems(rowData, { ...commonParams });
        total = list.total;
        state.orders = _.cloneDeep(list.orders);
        // eslint-disable-next-line prefer-destructuring
        data = list.data;
        _.map(data, (i) => {
          i.qtyToShipOld = i.qtyToShip;
          i.qtyToConsumeOld = i.qtyToConsume;
          i.quantityNeeded = i.qtyToShip + i.qtyToConsume;
          if(!state.isCutOrder) i.catQtyMaps = rowData.catQtyMaps;
          i.purpose = rowData.purpose;
          return i;
        });
        state.isLoading = false;
        data = LocalSort.filter(data, materialsTable?.value?.sort);
        state.materials = data;
        updateMaterialsData();
      } else {
        data = materialsData;
        state.materials = data;
        data = LocalSort.filter(data, materialsTable?.value?.sort);
        if (!_.isEmpty(BOMOrders)) state.orders = BOMOrders;
      }
      state.isAvailable = _.some(state.materials, (item) => item.quantity > _.get(item, 'reserved', 0));
      updateReserveStatus();
      return { data, total };
    };
    const refreshThings = async () => {
      state.loadTable++;
      updateMaterialsData(true);
      await refreshCard();
      await materialsTable.value?.refreshTable();
    };

    const deleteItems = async (item) => {
      const order = _.find(state.orders, (i) => i._id.toString() === item.cards[0]);
      for (const i of order.items) {
        if (i._id.toString() === item._id.toString()) i.archived.value = true;
      }
      const idx = _.findIndex(rowData.catQtyMaps, (i) => i.catId === item.catId);
      if (idx !== -1) {
        rowData.catQtyMaps.splice(idx, 1);
      }
      if (_.every(order.items, (i) => i.archived.value === true)
              && !_.isEmpty(rowData.fromInvOrderId) && order.notFromRequisition) {
        // eslint-disable-next-line max-len
        const invIdx = _.findIndex(rowData.fromInvOrderId, (i) => i.toString() === order._id.toString());
        rowData.fromInvOrderId.splice(invIdx, 1);
      }
      await rowData.save();
      if (_.every(order.items, (i) => i.archived.value === true) && !order.notFromRequisition) {
        const ids = order._id;
        await Order.addMaterials(
          { _id: rowData._id, project: { _id: order.project._id } },
          ids,
        );
        toast.success('BOM unlinked');
        await refreshThings();
      }
      updateMaterialsData(true);
      await order.save();
    };
    const onTableCellClicked = async (params) => {
      if (params.event === 'linkMaterialRequest') {
        ModalProgrammatic.open({
          component: LinkRequestingOrders,
          props: {
            card: rowData,
          },
          trapFocus: true,
          canCancel: false,
          events: {
            'refresh-table': async () => {
              await refreshThings();
            },
          },
          rootClass: 'modal-sm',
        });
      } else if (['addFromInventory', 'addFromCatalog'].includes(params.event)) {
        ModalProgrammatic.open({
          component: AddFromInventory,
          props: {
            card: rowData,
            isSelectedInventory: params.event === 'addFromInventory',
            shouldEmit: true,
          },
          trapFocus: true,
          canCancel: false,
          events: {
            'refresh-table': async () => {
              await refreshThings();
            },
          },
          rootClass: 'modal-sm',
        });
      } else if (params.type === 'delete') {
        const item = params.data;
        if (item.stage === 'preparation') {
          if (!item.order.notFromRequisition) {
            const confirmParam = {
              title: 'Deleting Materials',
              message: `Deleting this linked BOM will remove the items from the material List,
                        but will not remove Material Order. Continue?`,
              okButton: 'Delete',
              cancelButton: 'Cancel',
              type: 'danger',
              onConfirm: async () => {
                await deleteItems(item);
                await materialsTable.value?.refreshTable();
              },
            };
            DialogProgrammatic.confirm(confirmParam);
          } else {
            await deleteItems(item);
          }
          state.loadTable++;
          await materialsTable.value?.refreshTable();
        }
      } else if (params.type === 'reserveMaterials') {
        state.isAvailable = false;
        updateReserveStatus();
        // eslint-disable-next-line vue/no-mutating-props
        rowData.isAvailable = state.isAvailable;
        if(cardLoader.triggerLoading) cardLoader.triggerLoading(true);
        await store.getters.userPromise;
        if (_.every(state.materials, (item) => !item.available)) {
          toast.warning('No materials in inventory to reserve');
        }
        state.isLoading = true;
        try {
          // eslint-disable-next-line no-use-before-define
          const catIds = reqForReserve.value;
          await rowData.reserveCatalog(catIds, store.state.userData);
          await refreshCard();
          updateMaterialsData(true);
        } catch (e) {
          console.log('Error reserving', e);
        } finally {
          if(cardLoader.triggerLoading) triggerLoading(false);
        }
        state.isLoading = false;
      } else if (params.type === 'createBOMS') {
        await createBOMForOrders(rowData);
        toast.success('BOM created from item parts-list');
        await refreshCard();
      } else if (params.type === 'returnMaterials') {
        await ProductionManager.returnMaterialsToInv({cardId: rowData._id});
        await refreshCard();
      }
    };
    const reqForReserve = computed(() => rowData.reqForReserveQty(state.materials));

    const getApiMode = () => !tab?.tableProps?.isCardView;

    const updateQty = (val, item, type) => {
      item[type] = val;
      validateQTy({ item, type });
    };

    return {
      ...toRefs(state),
      loadData,
      onTableCellClicked,
      materialsTable,
      changeMaterialItem,
      validateQTy,
      calculateMax,
      qtyDisabled,
      deleteItems,
      reqForReserve,
      updateMaterialsData,
      getApiMode,
      updateQty,
    };
  },
});
</script>
<style scoped>
::v-deep(.o-table tbody) {
  min-height: 385px !important;
  max-height: 385px !important;
}
</style>
