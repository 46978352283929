<template>
    <div>
        <header class="modal-card-head">
            <h4 v-if="isSchedule" class="modal-card-title">
                Schedule a new delivery
            </h4>
            <h4 v-else-if="deliveries.length" class="modal-card-title">
                Receive Deliveries
            </h4>
            <h4 v-else class="modal-card-title">
                Receive Shipment
            </h4>
            <div class="is-divider-vertical"></div>
            <i class="icon-close is-clickable" @click="cancel()"></i>
        </header>
        <div class="modal-card create-assembly-part">
            <section v-if="isSchedule" class="modal-card-body has-background-white is-visible">
              <h4 class="has-text-black-bis is-italic is-size-3 line-height">
                  Order Details
              </h4>
                <div class="columns">
                    <div class="column"> <!-- 1st col -->
                        <div class="field">
                            <div class="field-label">
                                <label class="label">Name *</label>
                            </div>
                            <div class="field-body">
                                <input v-model="shipment.name" class="input" type="text"
                                       placeholder="Enter name">
                            </div>
                        </div>

                        <div class="field">
                            <div class="field-label">
                                <label class="label">Source Project</label>
                            </div>
                            <div class="field-body">
                                <h1> {{ shipment.delivery.currentProject.name }} </h1>
                            </div>
                        </div>

                        <div class="field">
                            <field-project-select
                                    titleLabel="Destination Project"
                                    :options="destProjList"
                                    :value="shipment.delivery.deliveryProject"
                                    :isEditing="true"
                                    @update:value="(v) => $_.set(shipment, 'delivery.deliveryProject', v)"
                            >
                            </field-project-select>
                        </div>
                        <div class="field">
                            <field-user-select
                                    titleLabel="Owner"
                                    :isEditing="true"
                                    :isShippingListView="true"
                                    :projectId="shipment.project._id"
                                    :value="shipment.delivery.owner"
                                    @update:value="(v) => $_.set(shipment.delivery, 'owner', v)"
                            >
                            </field-user-select>
                        </div>
                    </div>

                    <!-- 2nd col -->
                    <div class="column is-6">
                        <div class="columns">
                            <div class="column pb-0">
                                <div class="field">
                                    <div class="field-label">
                                        <label class="label">Ship Date *</label>
                                    </div>
                                    <div class="field-body">
                                        <div class="line-height has-text-black-bis">
                                            <mf-date
                                                    :item="shipment"
                                                    :input-props="{
                              kind: 'delivery.deliveryStart',
                              max: 'deliver.deliverBy',
                            }"
                                                    :is-edit="true"
                                            ></mf-date>
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- End of column -->
                            <div class="column pb-0">
                                <div class="field">
                                    <div class="field-label">
                                        <label class="label">Deliver Date *</label>
                                    </div>
                                    <div class="field-body">
                                        <mf-date
                                                :item="shipment"
                                                :input-props="{
                              kind: 'delivery.deliverBy',
                              min: 'delivery.deliverStart',
                            }"
                                                :is-edit="true"
                                        >
                                        </mf-date>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <div class="field-label">
                                <label class="label">Current Location</label>
                            </div>
                            <div class="field-body">
                                <h1> {{ shipment.delivery.currentLocation.name }} </h1>
                            </div>
                        </div>
                        <div class="field">
                            <field-location-select
                                    titleLabel="Destination Location"
                                    :isEditing="true"
                                    :value="shipment.delivery.deliveryLocation"
                                    :projectId="shipment.delivery.deliveryProject._id"
                                    :currentProjectId="shipment.delivery.currentProject._id"
                                    :deliveryProjectId="shipment.delivery.deliveryProject._id"
                                    :companyId="getCompanyIds"
                                    @update:value="(v) => $_.set(shipment, 'delivery.deliveryLocation', v)"
                            ></field-location-select>
                        </div>

                        <div class="field">
                            <field-user-select
                                    titleLabel="Recipient *"
                                    :isEditing="true"
                                    :isMulti="false"
                                    :isShippingListView="true"
                                    :projectId="shipment.project._id"
                                    :value="shipment.delivery.recipient"
                                    @update:value="(val) => $_.set(shipment, 'delivery.recipient', val)"
                            >
                            </field-user-select>
                        </div>
                    </div> <!-- End of columns -->
                </div>
            </section>
            <!--		    new code here-->
            <section v-else-if="deliveries.length" class="modal-card-body has-background-white">
                <div class="columns">
                    <div class="column" v-for="col of cardConfig" :key="col.label">
                        <h4 class="title is-size-3 has-text-weight-bold has-text-black-bis">
                            {{ 'Delivery Details' }}
                        </h4>
                              <base-column v-if="shipment.modalName" :fields="col.fields.multiGenericFields" :card="shipment"></base-column>
                              <base-column  v-else :fields="col.fields.genericFields" :card="shipment"></base-column>
                        <div v-for="f of col.fields.dateFields" :key="f.label" class="field">
                            <div class="field-label">
                                <label class="label is-size-3"> {{ f.title }} </label>
                            </div>
                            <div class="field-body">
                                <field-date
                                        :label="f.label"
                                        :isEdit="false"
                                        :item="shipment"
                                        labelPosition="is-left"
                                        :inputProps="f.inputProps"
                                        class="has-text-black-bis"
                                ></field-date>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <h4 class="title is-size-3 has-text-weight-bold has-text-black-bis">
                            Receive Items To {{ shipment._delivery.deliveryLocation.name }}
                        </h4>
                        <div class="field">
                            <div class="field-label">
                                <label class="label is-size-3"> Select Item Receipt Method </label>
                            </div>
                            <div class="field-body">
                                <div v-for="opt in radioOpts" :key="opt.key"
                                     class="mb-2 has-text-black-bis line-height">
                                    <input type="radio"
                                           :id="opt.key"
                                           :value="opt.key"
                                           v-model="postSaveAction"
                                    />
                                    <label :for="opt.key" class="has-text-black-bis">{{ opt.title }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <div class="field-label">
                                <label class="label is-size-3">Notify</label>
                            </div>
                            <div class="field-body">
                                <mf-multi-select v-model="shipment.alsoNotify"
                                                 :options="projectUsers[shipment.delivery.deliveryProject._id] || []"
                                                 :closeOnSelect="false"
                                                 label="name"
                                                 track-by="_id"
                                                 :multiple="true"
                                                 :searchable="true"
                                                 group-values="val" group-label="key">
                                </mf-multi-select>
                            </div>
                        </div>
                        <div class="field">
                            <div class="field-label">
                                <field-notes
                                        :value="shippingLabel.delivery.notes"
                                        @update:value="(val) => $_.set(shippingLabel, 'delivery.notes', val)">
                                </field-notes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-container">
                    <search-bar
                            :shouldEmit="true"
                            @search="getSearchValue"
                            placeholder="Search Items"
                    >
                    </search-bar>
                    <mf-table
                            ref="shipTable"
                            :apiMode="true"
                            :checkedRows="checkedRow"
                            :tableProps="tableCols.receiveDeliveries"
                            :loadData="loadData"
                            tableName="ShippingReceive"
                            @checkbox-toggled="itemSelected"
                    >
                    </mf-table>
                </div>
            </section>
            <section v-else class="modal-card-body has-background-white">
                <div class="columns">
                    <div class="column" v-for="col of cardConfig" :key="col.label">
                        <h4 class="title is-size-3 has-text-weight-bold has-text-black-bis">
                            {{ col.title }}
                        </h4>
                        <base-column :fields="col.fields.genericFields" :card="shipment"></base-column>
                        <div v-for="f of col.fields.dateFields" :key="f.label" class="field">
                            <div class="field-label">
                                <label class="label is-size-3"> {{ f.title }} </label>
                            </div>
                            <div class="field-body">
                                <field-date
                                        :label="f.label"
                                        :isEdit="false"
                                        :item="shipment"
                                        labelPosition="is-left"
                                        :inputProps="f.inputProps"
                                        class="has-text-black-bis"
                                ></field-date>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <h4 class="title is-size-3 has-text-weight-bold has-text-black-bis">
                            Receive Items To {{ shipment._delivery.deliveryLocation.name }}
                        </h4>
                        <div class="field">
                            <div class="field-label">
                                <label class="label is-size-3"> Select Item Receipt Method </label>
                            </div>
                            <div class="field-body">
                                <div v-for="opt in radioOpts" :key="opt.key"
                                     class="mb-2 has-text-black-bis line-height">
                                    <input type="radio"
                                           :id="opt.key"
                                           :value="opt.key"
                                           v-model="postSaveAction"
                                    />
                                    <label :for="opt.key" class="has-text-black-bis">{{ opt.title }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <div class="field-label">
                                <label class="label is-size-3">Notify</label>
                            </div>
                            <div class="field-body">
                                <mf-multi-select v-model="shipment.alsoNotify"
                                                 :options="projectUsers[shipment.delivery.deliveryProject._id] || []"
                                                 :closeOnSelect="false"
                                                 label="name"
                                                 track-by="_id"
                                                 :multiple="true"
                                                 :searchable="true"
                                                 group-values="val" group-label="key">
                                </mf-multi-select>
                            </div>
                        </div>
                        <div class="field">
                            <div class="field-label">
                                <field-notes
                                        :value="shippingLabel.delivery.notes"
                                        @update:value="(val) => $_.set(shippingLabel, 'delivery.notes', val)">
                                </field-notes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-container">
                    <search-bar
                            :shouldEmit="true"
                            @search="getSearchValue"
                            placeholder="Search Items"
                    >
                    </search-bar>
                    <mf-table
                            ref="shipTable"
                            :apiMode="true"
                            :checkedRows="checkedRow"
                            :tableProps="tableProps"
                            :loadData="loadData"
                            tableName="ShippingReceive"
                            @checkbox-toggled="itemSelected"
                    >
                        <template v-slot:availableQty="{ rowData: item }">
                            <qty-input
                                    v-if="item.showInput"
                                    v-model.number="item.selectedQty"
                                    :value="item.selectedQty"
                                    :max="setItemQtyLimit(item)"
                                    @input="itemQtyChanged($event, item)"
                                    :roundTo="4"
                            >
                            </qty-input>
                            <div class="is-flex is-pulled-right" v-else>
                                {{ item.available }}
                            </div>
                            <span class="icon" v-show="item.showPopup">
							                <i class="fa-question-circle fa f-13">
							                </i>
                            </span>
                        </template>
                        <template v-slot:selectError="{ rowData: item }">
                            <mf-multi-select
                                    :modelValue="item.issueNote"
                                    :options="errorOptions"
                                    :closeOnSelect="true"
                                    label="title"
                                    track-by="key"
                                    :multiple="false"
                                    :searchable="false"
                                    @update:modelValue="item.issueNote = $event"
                            />
                        </template>
                    </mf-table>
                </div>
            </section>
            <footer class="modal-card-foot is-justify-content-flex-end">
                <button class="button is-outlined" @click="$emit('close', true)">
                    Cancel
                </button>
                <button v-if="isSchedule" class="button has-background-black-bis"
                        @click="scheduleNewDelivery()"
                >
                    Schedule Delivery
                </button>
                <button v-else-if="deliveries.length" class="button has-background-black-bis"
                        @click="receiveDeliveries(postSaveAction)"
                        :disabled="isDisabled"
                >
                    Receive
                </button>
                <button v-else class="button has-background-black-bis"
                        @click="receiveShipment(postSaveAction)"
                        :disabled="isDisabled"
                >
                    Receive
                </button>
            </footer>
        </div>
        <o-loading
                :full-page="true"
                :active="isLoading"
                :can-cancel="true"
        ></o-loading>
    </div>
</template>

<script>
import {
  computed, reactive, toRefs, ref, onMounted, defineAsyncComponent, onBeforeMount, inject,
} from 'vue';
import tableDefinition from '@/components/table-cols/shippingRecieveConfig';
import _ from 'lodash';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import Shipping from '@/models/Shipping';
import QtyInput from '@/components/fields/QtyInput.vue';
import { useRouter } from 'vue-router';
import BaseCardColumn from '@/components/card-edit/BaseCardColumn.vue';
import SearchBar from '@/components/SearchBar.vue';
import Projects from '@/models/Projects';
import Users from '@/models/Users';
import uuid from 'uuid/v4';
import MfDate from '@/components/abstract/MfDate.vue';
import GeneralShippingMixin from '@/components/mixins/GeneralShippingMixin';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default {
  name: 'ShippingReceive',
  props: {
    shippingLabel: {
      type: Object,
      default: () => {
      },
    },
    shippingId: {
      type: String,
	    default: () => '',
    },
    projectId: {
      type: String,
      default: () => '',
	  },
    deliveries: {
      type: Array,
      default: () => []
    },
    redirectPath: {
      type: String,
      default: () => '/logistics/shipping/order-view',
    },
    isSchedule: false,
  },
  components: {
    MfTable,
    'qty-input': QtyInput,
    'base-column': BaseCardColumn,
    'search-bar': SearchBar,
    MfDate,
  },
  setup(props, { emit }) {
    const emitter = inject('emitter');
    const shipment = props.shippingLabel;
    const { scheduleDelivery } = GeneralShippingMixin();
    const toast = useToast();
    const store = useStore();
    const router = useRouter();
    const shipTable = ref(null);
    const state = reactive({
      tableProps: tableDefinition.tableBasics,
      cardConfig: tableDefinition.modalBasics,
      tableCols: tableDefinition,
      checkedRow: [],
      itemsData: [],
      isLoading: false,
      selectedItems: [],
      addQtyToSourcing: false,
      searchedValue: false,
      errorOptions: [
        {
          title: 'Damage',
          key: 'damage',
        }, {
          title: 'Missing',
          key: 'missing',
        }, {
          title: 'Not to spec',
          key: 'notToSpec',
        },
      ],
      radioOpts: [
        {
          title: 'Leave items packaged (for future shipping)',
          key: 'receive',
          postSaveAction: 'receive',
        },
        {
          title: 'Release to Inventory (for later use)',
          key: 'release',
          postSaveAction: 'release',
        },
        {
          title: 'Mark as immediately consumed (not stored)',
          key: 'fulfilled',
          postSaveAction: 'fulfilled',
        },
      ],
      postSaveAction: 'receive',
      projectUsers: {},
      destinationProjects: [],
    });

    const fetchUsers = async (projectId) => {
      const projectUsers = await Projects.linkedProjectUsers([projectId]);
      return Users.groupUsers(projectUsers);
    };

    onBeforeMount(async () => {
      state.destinationProjects.push(shipment._delivery.deliveryProject);
      let { commonStockProject } = store.state.queryParams;
      commonStockProject = !_.isEmpty(commonStockProject)
        ? commonStockProject : await Projects.getCommonStockProject();
      state.destinationProjects.push(commonStockProject);
    });

    onMounted(async () => {
      const projectId = shipment.delivery.deliveryProject._id;
      const users = await fetchUsers(shipment.delivery.currentProject._id);
      if (users.length) state.projectUsers[projectId] = users;
    });
    // computed
    const isDisabled = computed(() => _.isEmpty(state.selectedItems));
    const destProjList = computed(() => {
      if (shipment._delivery.currentProject.isCommonStockProject) {
        return state.allProjects;
      }
      return state.destinationProjects;
    });

    const getCompanyIds = computed(() => {
      const { userData } = store.state;
      let companyId = userData.company;
      if (store.getters.isViewer()) {
        companyId = store.getters.selectedIdsForKey('companies', false);
      }
      return companyId;
    });
    // methods
    const getSearchValue = ((searchText) => {
      state.searchedValue = searchText;
      shipTable.value.refreshTable();
    });
    const loadData = (() => {
      if (props.deliveries.length) {
        state.checkedRow = props.deliveries;
        state.selectedItems = props.deliveries;
        return {
          data: props.deliveries,
          total: props.deliveries.length,
        };
      }
      const items = shipment.items.filter((item) => {
        if (item.qtyToShip === 0) {
          return false;
        }
        item.available = item.quantity;
        _.set(item, 'selectedQty', item.available);
        if (state.searchedValue) {
          return item.name.toLowerCase()
            .includes(state.searchedValue.toLowerCase());
        }
        _.set(item, 'uid', uuid());
        return item;
      });
      if (_.isEmpty(state.selectedItems) && _.isEmpty(state.searchedValue)) {
        state.selectedItems = items;
        state.selectedItems.forEach((item) => {
          _.set(item, 'selected', true);
          _.set(item, 'showInput', true);
          _.set(item, 'selectedQty', item.available);
        });
      }
      state.checkedRow = items;
      const ItemList = {
        data: items,
        total: items.length,
      };
      return ItemList;
    });
    const cancel = (val = false) => {
      emit('close', val);
      if (store.state.activeScreen !== 'shipping-edit') {
        router.push({ path: props.redirectPath });
      }
      if (val && store.state.activeScreen === 'shipping-edit') {
        emitter.emit('refreshCardOnKey');
      }
    };
    const releaseToInv = async (currentLoc) => {
      try {
        state.isLoading = true;
        await shipment.relaunchAndRelease(currentLoc);
        toast.success(`Items Released To Inventory at Location ${currentLoc.name}`);
        state.isLoading = false;
        cancel();
      } catch (e) {
        state.isLoading = false;
        console.log('Error', e);
      }
    };

    function getMessage(action) {
      if (action === 'cancelDelivery') return 'Delivery cancelled successfully';
      if (action === 'receive') return 'Shipment received successfully';
      if (action === 'fulfilled') return 'Shipment marked fulfilled';
      return '';
    }
    const takeActions = async (shipment, postSaveAction) => {
      if (postSaveAction === 'release') {
        await releaseToInv(shipment._delivery.deliveryLocation);
        return;
      }
      let newPS = {};
      try {
        newPS = await shipment.save();
      } catch (e) {
        console.log(e);
        throw e;
      }
      if (postSaveAction === 'receive') {
        newPS = await shipment.receive();
        let currentProject = store.getters
          .findProject({ _id: newPS.delivery.currentProject._id });
        let deliveryProject = store.getters
          .findProject({ _id: newPS.delivery.deliveryProject._id });
        if (_.isEmpty(currentProject) && newPS.delivery.currentProject._id
          === store.state.queryParams.commonStockProject._id) {
          currentProject = store.state.queryParams.commonStockProject;
        }
        if (_.isEmpty(deliveryProject) && newPS.delivery.deliveryProject._id
          === store.state.queryParams.commonStockProject._id) {
          deliveryProject = store.state.queryParams.commonStockProject;
        }
        if (!currentProject?.isGI && deliveryProject?.isGI) {
          await Shipping.releaseToInventory({ shippingLabelId: newPS.relayedToShipment });
        }
      } else if (postSaveAction === 'fulfilled') {
        newPS = await shipment.markFulfilled();
        const currentProject = store.getters
          .findProject({ _id: newPS.delivery.currentProject._id });
        const deliveryProject = store.getters
          .findProject({ _id: newPS.delivery.deliveryProject._id });
        if (currentProject.isGI && !deliveryProject.isGI) {
          await Shipping.receiveAll({
            shippingLabelId: newPS.relayedToShipment,
            isFinal: true,
          });
        }
      }
    }
		const receiveDeliveries = async (postSaveAction) => {
      state.isLoading = true;
      for await (const delivery of props.deliveries) {
        if (delivery) {
          await takeActions(delivery, postSaveAction);
        }
      }
      const message = getMessage(postSaveAction);
      if (message) toast.success(message);
      cancel(true);
		}
    const receiveShipment = async (postSaveAction) => {
      state.isLoading = true;
      const validate = shipment.validateLabel();
      if (validate.error) {
        toast.error(validate.errText);
        return;
      }
      // prevent item processing when label is in-transit since its unnecessary
      try {
        for (const item of state.checkedRow) {
          item.selectedQty = item.selected ? (item.selectedQty || 0) : 0;
          if (item.selectedQty < item.quantity) {
            item.isUnderDeliver = true;
            item.underDeliver = item.quantity - item.selectedQty;
            shipment.isUnderDeliver = true;
          }
        }
        await takeActions(shipment, postSaveAction)
        const message = getMessage(postSaveAction);
        if (message) toast.success(message);
        cancel(true);
      } catch (e) {
        toast.error(e.message || e.data.message || 'Error while processing shipment. Please try again later', '');
        cancel();
        console.log('Error while processing shipment', e);
      }
      state.isLoading = false;
    };
    const itemQtyChanged = ((newQty, item) => {
      if (item.selectedQty > (item.quantity || item.available)) {
        item.selectedQty = item.available;
      }
    });
    const itemSelected = ((selectedItems) => {
      if (state.selectedItems && state.selectedItems.length > 0) {
        state.selectedItems.forEach((item) => {
          _.set(item, 'selected', false);
          _.set(item, 'showInput', false);
        });
      }
      selectedItems.forEach((item) => {
        _.set(item, 'selected', true);
        _.set(item, 'showInput', true);
        _.set(item, 'selectedQty', item.available);
      });
      state.selectedItems = selectedItems;
    });

    const scheduleNewDelivery = (async () => {
      state.isLoading = true;
      await scheduleDelivery(shipment);
      state.isLoading = false;
      emit('close');
    });

    const setItemQtyLimit = ((item) => {
      // Function to set max limit for qty edit field
      if (!shipment._id) {
        return (item.initialAvailable || item.available || item.quantity);
      }
      if (item.isShipmentItem) {
        /* Existing shipment items meeting following conditions:
          1.item is a material item
          AND
          2.has catId (OR) has come freshly from a MM order (completed 0 hops)
        */
        if (
          _.get(item, 'source.kind', '') === 'Sourcing'
          && (item.catId || _.get(item, 'source.from', '') === 'card')
        ) {
          return 9999;
        }
        return item.available;
      }
      if (item.isMaterialItem) {
        // Newly added material items (from existing material, new material, new material item)
        return 9999;
      }
      return item.available;
    });
    return {
      ...toRefs(state),
      loadData,
      setItemQtyLimit,
      itemQtyChanged,
      itemSelected,
      receiveShipment,
      cancel,
      isDisabled,
      getSearchValue,
      shipTable,
      shipment,
      scheduleNewDelivery,
      getCompanyIds,
      destProjList,
      takeActions,
      receiveDeliveries
    };
  },

};
</script>

<style scoped>
::v-deep(.o-table tbody) {
    min-height: 200px !important;
    max-height: 200px !important;
}
</style>
