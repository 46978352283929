<template>
  <nav class="navbar" role="card-actions" aria-label="card-edit">
    <div class="navbar-menu">
      <div class="navbar-start ml-2" @click.prevent="goBack()">
        <a
          class="navbar-item is-size-3 is-paddingless has-text-weight-bold has-background-white"
        >
          {{ config.backBtnText }}
        </a>
      </div>

      <div class="navbar-end" v-if="hasProjects">
        <div class="navbar-item">
          <IconField :rowData="card" :rowField="{ id: 'lmvIcon'}" :projects="allProjects" :isCardSubHeader="true"/>
        </div>
        <div class="navbar-item" v-for="btn in config.actions" :key="btn">
          <button
            :class="icons[btn]"
            class="button has-text-black-bis"
            @click="actionsClicked(btn)"
          ></button>
        </div>
         <div class="is-divider-vertical ml-0 mr-0"></div>
        <div class="navbar-item">
          <figure class="image is-48x48" >

          </figure>
        </div>
      </div>
    </div>
    <notify-users
      v-if="showNotify"
      :isActive="showNotify"
      :card="card"
      @close="showNotify = false"
    >
    </notify-users>
  </nav>
  <activity-log
                :id="activityDetail.id"
                :projectId="activityDetail.projectId"
                :isActive="isActive"
                :type = "module"
                :orderName="activityDetail.orderName"
                @update:isActive="isActive=false"></activity-log>
</template>

<script>
import { reactive, toRefs, onBeforeMount, computed } from 'vue';
import {
  keys, pickBy, cloneDeep, unset,
} from 'lodash';
import { useRouter } from 'vue-router';
import ActivityLog from '@/components/ActivityLog.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import NotifyUsers from '@/components/modals/NotifyUsers.vue';
import IconField from '../../components/fields/IconField.vue';
import Projects from '../../models/Projects'
import { useStore } from 'vuex';

export default {
  name: 'CardEditSubHeader',
  components: {
    ActivityLog,
    NotifyUsers,
    IconField
  },
  props: {
    type: String,
    module: String,
    stage: String,
    card: {
      type: Object,
      default: () => ({}),
    },
    status: String,
  },
  setup(props) {
    const { card, status } = props;
    const router = useRouter();
    const { getConfigDetails, printQr, printShipQr } = CardEditMixin();
    const state = reactive({
      isActive: false,
      config: {},
      activityDetail: {
        id: '',
        projectId: '',
        activityDetail: '',
      },
      icons: {
        activity: 'icon-activityhistory',
        notifyUsers: 'icon-notifyusers',
        save: 'icon-checkmark',
        subscribe: 'icon-subscribe',
        qrPrint: 'icon-qrcode',
      },
      showNotify: false,
      allProjects: [],
    });

    const shippingStages = ['not-started', 'in-transit', 'complete', 'delivery', 'released-to-inventory',
      'fulfilled', 'in-storage', 'mixed-shipping'];

    const goBack = () => {
      let path = '/home';
      const backUrl = {
        Materials: [...shippingStages, 'mixed', 'rejected'].includes(card._customStage) ? '/materials/preparation' : `/materials/${card._customStage}`,
        Prefabs: '/prefab/planning',
        // eslint-disable-next-line no-nested-ternary
        ProductionOrder: !card.isManager ? '/prefab/coordination' : [...shippingStages, 'mixed'].includes(card._customStage) ? '/manager/detailing' : `/manager/${card._customStage}`,
        Sourcing: shippingStages.includes(card.stage) ? '/materials/ordering' : `/materials/${card.stage}`,
      };

      if (card?.type === 'pick' || card?.type === 'stock') path = '/logistics/transfer-request';
      else if ([...shippingStages, 'mixed', 'consumed', 'zombie'].includes(status)) {
        path = '/logistics/shipping/order-view';
      } else if (props.type === 'kit-view-edit') {
        if (window.history.length) {
          router.go(-1);
          return;
        }
      } else {
        path = backUrl[card.__t];
      }
      router.push({ path });
    };
    // eslint-disable-next-line max-len
    const configDetails = cloneDeep(getConfigDetails(props.type, props.card.__t, props.card._customStage || props.card.stage));
    state.config = configDetails.cardSubHeader;
    if (['complete', 'delivery'].includes(props.card._customStage || props.card.stage));
    else if (!['material-edit-ordering', 'manager-edit-detailing', 'manager-edit-manufacturing', 'manager-edit-qa', 'shipping-edit'].includes(props.type)) {
      unset(state.config.actions, 'qrPrint');
    }
    state.config.actions = keys(pickBy(state.config.actions));
    const actionsClicked = (btn) => {
      // do actions based on btn click;
      if (btn === 'activity') {
        state.isActive = true;
        const routeParams = router.currentRoute.value.params;
        state.activityDetail.id = routeParams.cardId || routeParams?.requestId;
        state.activityDetail.projectId = routeParams.projectId;
        state.activityDetail.orderName = card.name;
      } else if (btn === 'notifyUsers') {
        state.showNotify = true;
      } else if (btn === 'qrPrint') {
        if (props.type === 'shipping-edit') {
          printShipQr(card);
        } else printQr(card);
      }
    };
    const hasProjects = computed(() => {
      return state.allProjects.length;
    });
    onBeforeMount(async () => {
      state.allProjects = await Projects.allProjects();
    });
    return {
      ...toRefs(state),
      actionsClicked,
      goBack,
      hasProjects,
    };
  },
};
</script>

<style scoped>
.is-48x48 {
  width: 55px !important;
}
</style>
